import React, { FC } from "react";
import styled from "styled-components";
import { Loader } from "../components/atoms/Loader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  align-items: center;
`;

const Message = styled.span`
  color: white;
  margin-top: 1rem;
`;

const LoadingPage: FC = () => (
  <Container>
    <Loader isLoading />
    <Message>Loading...</Message>
  </Container>
);

export default LoadingPage;
