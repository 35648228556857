import React, { FC } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { leadStagesSelector } from '../../../store/leads';

import { ConfirmationModal } from './ConfirmationModal';
import { summonFlashMessage } from '../../../helpers/flashMessage';
import { getLeadByIdFull } from '../../../types';

interface DeleteLeadModalProps {
  leadInformation: Partial<getLeadByIdFull>;
  onSuccess: () => void;
  closeModal: () => void;
}

export const DeleteLeadModal: FC<DeleteLeadModalProps> = ({
  leadInformation,
  onSuccess,
  closeModal,
}) => {
  const leadStages = useSelector(leadStagesSelector);
  const handleDeleteConfirmation = async () => {
    try {
      if (!leadInformation?.lead?.id) {
        throw new Error('No lead id found');
      }

      await axios.delete(`/leads/${leadInformation.lead.id}`);
      summonFlashMessage('Lead Deleted', 'success');
      onSuccess();
    } catch (err) {
      summonFlashMessage('Error Deleting Lead', 'error');
      console.log(err);
    } finally {
      closeModal();
    }
  };

  const fullname = `${leadInformation?.lead?.firstName} ${leadInformation?.lead?.lastName}`;
  const lifeCycle = leadInformation?.lead?.lifeCycle;
  const namedLeadStage = leadStages.find(
    (stage) => stage.id === lifeCycle
  )?.type;

  return (
    <ConfirmationModal
      onConfirm={handleDeleteConfirmation}
      confirmTitle="Confirm Delete Lead"
      confirmText={`Are you sure you want to remove ${namedLeadStage} ${fullname} from your leads?`}
    />
  );
};
