import { FC } from 'react';

import { CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from 'styled-components';

import { deleteRed, orangeMain } from '../../constants/colors';
import { formatAmountAsUSD } from '../../helpers/formatAmountAsUSD';
import { formatTimestamp } from '../../helpers/formatTimestamp';
import { estimateType } from '../../types';

interface EstimateProps {
  estimate: Partial<estimateType>;
  onEstimateDelete: () => void;
  onEstimateEdit: () => void;
}

const buttonSx = (isDelete?: boolean) => ({
  color: 'black',
  backgroundColor: isDelete ? deleteRed : orangeMain,
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
    boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
    backgroundColor: isDelete ? deleteRed : orangeMain,
  },

  '@media (max-width: 500px)': {
    marginBottom: '1rem',
  },
});

const dividerSx = {
  '&.MuiDivider-root': {
    backgroundColor: 'darkgray',
  },
};

const cardSx = {
  '&.MuiPaper-root': {
    marginBottom: '2rem',
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${orangeMain}`,
  },
};

const cardContentSx = {
  '&.MuiCardContent-root': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',

    '@media (max-width: 700px)': {
      flexDirection: 'column',
    },
  },
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Estimate: FC<EstimateProps> = ({
  estimate,
  onEstimateDelete,
  onEstimateEdit,
}) => {
  const handleEstimateDelete = () => onEstimateDelete();
  const handleEstimateEdit = () => onEstimateEdit();

  return (
    <Card sx={cardSx} elevation={5}>
      <HeaderContainer>
        <h3 style={{ marginBottom: 0, paddingLeft: '16px' }}>
          {estimate.subject}
        </h3>
      </HeaderContainer>
      <Divider sx={dividerSx} variant="middle" />
      <CardContent sx={cardContentSx}>
        <div>
          <p>
            <b>Notes:</b> {estimate.notes}
          </p>
          <p>
            <b>Terms:</b> {estimate.terms}
          </p>
          <p>
            <b>Expiration Date:</b>{' '}
            {estimate?.expirationDate
              ? formatTimestamp(estimate?.expirationDate)
              : 'N/A'}
          </p>
        </div>

        <Table sx={{ maxWidth: 500 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Item Details</b>
              </TableCell>
              <TableCell align="right">
                <b>Amount</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estimate?.details
              ? estimate.details.map((row) => (
                  <TableRow
                    key={row.itemDetails}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.itemDetails}
                    </TableCell>
                    <TableCell align="right">
                      {row?.amount
                        ? formatAmountAsUSD(Number(row.amount))
                        : '--'}
                    </TableCell>
                  </TableRow>
                ))
              : null}
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                {estimate?.total
                  ? formatAmountAsUSD(Number(estimate.total))
                  : '--'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>

      <CardActions>
        <Button sx={buttonSx()} size="small" onClick={handleEstimateEdit}>
          Edit Estimate
        </Button>
        <Button sx={buttonSx(true)} size="small" onClick={handleEstimateDelete}>
          Delete Estimate
        </Button>
      </CardActions>
    </Card>
  );
};
