import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';

import { leadSnareAdminSelector } from '../store/site';

/**
 * DOCS
 * https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
 */

export const InternalAdminRoute = (): React.ReactElement => {
  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);

  if (!isLeadSnareAdmin) {
    // Flash error message
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};
