export function parseUSD(currencyString: string) {
  // Remove the "$" sign and any commas using regex
  const numberString = currencyString.replace(/[$,]/g, '');

  // Parse the string to a floating-point number
  const number = parseFloat(numberString);

  // Return the parsed number
  return number;
}
