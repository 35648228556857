import React, { FC, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';

import { userIdSelector } from '../../../store/account';
import { leadSnareAdminSelector } from '../../../store/site';

import { ModalHeader, ModalSubHeader } from '../../atoms/ModalHeader';
import HorizontalLine from '../../atoms/HorizontalLine';

import { orangeMain } from '../../../constants/colors';
import Switch from '@mui/material/Switch/Switch';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

const label = { inputProps: { 'aria-label': 'Set LeadSnareAdmin Only Note' } };

interface NewNoteModalProps {
  leadId?: string;
  clientId?: number;
  userId?: string;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

export const NewNoteModal: FC<NewNoteModalProps> = ({
  leadId,
  clientId,
  closeModal,
  onSuccess,
}) => {
  const [currentNoteInputValue, setCurrentNoteInputValue] = useState('');
  const [isAdminOnlyNote, setIsAdminOnlyNote] = useState(false);
  const userId = useSelector(userIdSelector);
  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsAdminOnlyNote(event.target.checked);

  const handleAddNewNote = async () => {
    if (currentNoteInputValue.length) {
      try {
        await axios.post('/notes/new', {
          leadId,
          clientId,
          userId,
          note: currentNoteInputValue,
          adminOnly: isAdminOnlyNote,
        });

        await onSuccess();
        summonFlashMessage('Note Added', 'success');
      } catch (err) {
        summonFlashMessage('Error Adding Note', 'error');
        console.log(err);
      } finally {
        closeModal();
      }
    } else {
      summonFlashMessage('Note Cannot Be Empty', 'warning');
    }
  };

  return (
    <Container>
      <ModalHeader>Create New Note</ModalHeader>
      <HorizontalLine />
      <ModalSubHeader>Note</ModalSubHeader>
      <ModalBody>
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          multiline
          onChange={(e) => setCurrentNoteInputValue(e.target.value.trim())}
        />
        <HorizontalLine />
        {isLeadSnareAdmin && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ModalSubHeader style={{ margin: 0 }}>
              LeadSnare Admin Only Note?
            </ModalSubHeader>
            <Switch
              {...label}
              checked={isAdminOnlyNote}
              onChange={handleSwitchChange}
              color="warning"
            />
          </div>
        )}
        <Button
          variant="contained"
          size="medium"
          onClick={handleAddNewNote}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Add Note
        </Button>
      </ModalBody>
    </Container>
  );
};
