import React, { FC, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import Switch from '@mui/material/Switch/Switch';

import { leadSnareAdminSelector } from '../../../store/site';

import { ModalHeader, ModalSubHeader } from '../../atoms/ModalHeader';
import HorizontalLine from '../../atoms/HorizontalLine';

import { orangeMain } from '../../../constants/colors';

import { noteType, withTimeStamps } from '../../../types';
import { apiDBResponse } from '../../../types/api';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

interface EditNoteModalProps {
  note: string;
  adminOnly: boolean;
  noteId: number;
  leadId?: string;
  userId?: string;
  clientId?: string;
  target?: string;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

const label = { inputProps: { 'aria-label': 'Set LeadSnareAdmin Only Note' } };

export const EditNoteModal: FC<EditNoteModalProps> = ({
  note,
  adminOnly,
  noteId,
  closeModal,
  onSuccess,
}) => {
  const [currentNoteInputValue, setCurrentNoteInputValue] = useState(note);
  const [isAdminOnlyNote, setIsAdminOnlyNote] = useState(adminOnly);
  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsAdminOnlyNote(event.target.checked);

  const handleEditNote = async () => {
    const payload = {
      note: currentNoteInputValue,
      adminOnly: isAdminOnlyNote,
    };

    try {
      const updatedNote = (await axios.patch(
        `/notes/${noteId}`,
        payload
      )) as AxiosResponse<apiDBResponse<withTimeStamps<noteType>>>;

      if (!updatedNote.data.success || !updatedNote.data.result) {
        // Flash Error, Failed to update note
        summonFlashMessage('Error Updating Note', 'error');
        closeModal();
        return;
      }

      summonFlashMessage('Note Updated', 'success');
      await onSuccess();
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Updating Note', 'error');
      // Flash Error, Failed to update note
    } finally {
      closeModal();
    }
  };

  return (
    <Container>
      <ModalHeader>Edit Note</ModalHeader>
      <HorizontalLine />
      <ModalSubHeader>Note</ModalSubHeader>
      <ModalBody>
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          multiline
          value={currentNoteInputValue}
          onChange={(e) => setCurrentNoteInputValue(e.target.value)}
        />
        <HorizontalLine />
        {isLeadSnareAdmin && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ModalSubHeader style={{ margin: 0 }}>
              LeadSnare Admin Only Note?
            </ModalSubHeader>
            <Switch
              {...label}
              checked={isAdminOnlyNote}
              onChange={handleSwitchChange}
              color="warning"
            />
          </div>
        )}
        <Button
          variant="contained"
          size="medium"
          onClick={handleEditNote}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Update Note
        </Button>
      </ModalBody>
    </Container>
  );
};
