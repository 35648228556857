import React from 'react';
import styled from 'styled-components';
import { orangeMain } from '../../constants/colors';

export const InternalTag = styled.span`
  font-style: italic;
  font-size: 12px;
  font-weight: 600;
  background-color: ${orangeMain};
  color: black;
  padding: 2px 5px;
  border-radius: 5px;
  border: 2px solid black;
`;
