/**
 * Use to Parse URL Search Params into JSON Object
 * @param searchString use window.location.search
 * @returns JSON Object of URL Search Params
 */

export const turnSearchStringIntoObject = (searchString: string) => {
  const searchParams = new URLSearchParams(searchString);
  const searchObject: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    searchObject[key] = value;
  });
  return searchObject;
};

export const clearSearchParams = () => {
  window.history.replaceState(
    {},
    '',
    window.location.pathname + window.location.hash
  );
};
