import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { clientType } from '../../types';
import { orangeMain } from '../../constants/colors';

const Container = styled.button`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: space-between;
  padding: 5px;
  margin: 10px;
  border: 2px solid ${orangeMain};
  border-radius: 5px;
  background-color: white;
  transition: all 0.25s ease-in-out;
  box-shadow: 2px 2px 5px 2px rgba(189, 195, 199, 1);

  &:hover {
    transform: scale(1.04, 1.02);
    box-shadow: 3px 3px 5px 4px rgba(189, 195, 199, 1);
  }
`;

const ClientName = styled.h3`
  font-weight: 600;
  margin: 0;
  font-style: italic;
  text-align: left;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
`;

interface ClientProps {
  client: clientType;
}

export const Client: FC<ClientProps> = ({ client }) => {
  const navigate = useNavigate();

  const handleClientClick = () => {
    navigate(`/business/${client.id}`);
  };

  return (
    <Container onClick={handleClientClick}>
      <ClientName>{client.companyName}</ClientName>
      <IDSpan>{client.websiteURL}</IDSpan>
      <IDSpan style={{ marginTop: '5px' }}>ID: {client.id}</IDSpan>
    </Container>
  );
};
