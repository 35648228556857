import React from "react";
import styled from "styled-components";
import LeadSnarePng from "../../../images/LeadSnareLogoTrim.png";
import { IconProps } from "../../../types";

const Img = styled.img<{ width?: number; height?: number }>`
  ${({ width }) => width && `width: ${width}px;`}
  ${({ height }) => height && `height: ${height}px;`}
  cursor: pointer;
`;

export const LeadSnareLogo = ({
  height,
  width,
  onClick,
}: IconProps): JSX.Element => (
  <Img
    width={width}
    onClick={onClick}
    height={height}
    src={LeadSnarePng}
    alt="LeadSnareLogo"
  />
);
