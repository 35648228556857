import axios, { AxiosResponse } from 'axios';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';

import { RootState } from './index';
import {
  clientType,
  clientTypeFull,
  getAllClientsInfoWithIdsResults,
  userType,
} from '../types';
import { apiDBResponse, apiGeneralResponse } from '../types/api';
import { summonFlashMessage } from '../helpers/flashMessage';

export interface AccountState {
  userInfo?: userType;
  userBusinesses: Array<clientTypeFull>;
  userBusinessMap: {
    [key: clientType['id']]: clientTypeFull;
  };
  userId: number;
  propelAuthId: string;
  // address: Partial<addressType>;
}

const generateInitialState = (): AccountState => ({
  userInfo: undefined,
  userBusinesses: [],
  userBusinessMap: {},
  userId: 0,
  propelAuthId: '',
  // address: {},
});

// ASYNC THUNKS
export const fetchUserByPropelAuthId = createAsyncThunk<any, string>(
  'account/fetchUserByPropelAuthId',
  async (id, { getState, rejectWithValue }) => {
    try {
      const userData = (await axios({
        method: 'get',
        url: `/users/${id}?propelAuthId=true`,
      })) as AxiosResponse<apiGeneralResponse<userType>>;

      if (userData.data.success && userData.data.result) {
        return userData.data.result;
      }

      console.error('Error Getting User By PropelAuthId:', userData.data.error);
      summonFlashMessage('Failed Getting User Information', 'error');
      rejectWithValue({});
    } catch (err) {
      console.error('Error Getting User By PropelAuthId:', err);
      summonFlashMessage('Failed Getting User Information', 'error');
      rejectWithValue({});
    }
  }
);

export const fetchUserClientsByUserId = createAsyncThunk<any>(
  'account/fetchUserClientsByUserId',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { account } = getState() as RootState;
      const { userId } = account;

      const clientData = (await axios({
        method: 'post',
        url: `/userClients/${userId}/clients?withClientInfo=true`,
      })) as AxiosResponse<apiDBResponse<getAllClientsInfoWithIdsResults>>;

      if (clientData.data.success && clientData.data.result) {
        return clientData.data.result;
      }

      console.error('Error Getting Clients By UserId:', clientData.data.error);
      summonFlashMessage('Failed Getting User Clients', 'error');
      rejectWithValue([]);
    } catch (err) {
      console.error('Error Getting Clients By UserId:', err);
      summonFlashMessage('Failed Getting User Clients', 'error');
      rejectWithValue([]);
    }
  }
);

type updateUserByIdType = {
  userId: number;
  userInformation: Partial<userType>;
};

export const updateUserById = createAsyncThunk<any, updateUserByIdType>(
  'account/updateUserById',
  async (userInfo, { getState, rejectWithValue }) => {
    try {
      const { userId, userInformation } = userInfo;

      const userData = await axios({
        method: 'patch',
        url: `/users/${userId}`,
        data: userInformation,
      });

      if (userData.data.success && userData.data.result) {
        summonFlashMessage('Success Updating User By ID', 'success');
        return userData.data.result[0];
      }

      console.error('Error Updating User by Id:', userData.data.error);
      summonFlashMessage('Failed Updating User By ID', 'error');
      rejectWithValue({});
    } catch (err) {
      console.error('Error Updating User by Id:', err);
      summonFlashMessage('Failed Updating User By ID', 'error');
      rejectWithValue({});
    }
  }
);

// REDUCERS
const accountSlice: Slice<AccountState> = createSlice({
  name: 'account',
  initialState: generateInitialState(),
  reducers: {
    setPropelAuthId: (state: AccountState, action: PayloadAction<string>) => ({
      ...state,
      propelAuthId: action.payload,
    }),
    // setName: (state: AccountState, action: PayloadAction<string>) => {
    //   state.name = action.payload;
    // },
    // setProfileId: (state: AccountState, action: PayloadAction<string>) => {
    //   state.profileId = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserByPropelAuthId.fulfilled, (state, action) => {
      return {
        ...state,
        userInfo: action.payload as userType,
        userId: action.payload.id,
      };
    });
    builder.addCase(fetchUserByPropelAuthId.rejected, (state) => {
      return {
        ...state,
      };
    });
    builder.addCase(updateUserById.fulfilled, (state, action) => {
      return {
        ...state,
        userInfo: action.payload as userType,
        userId: action.payload.id,
      };
    });
    builder.addCase(updateUserById.rejected, (state) => {
      return {
        ...state,
      };
    });
    builder.addCase(fetchUserClientsByUserId.fulfilled, (state, action) => {
      const data = action.payload as Array<getAllClientsInfoWithIdsResults>;

      const conversion = data.map((client) => {
        return {
          id: client.companyId,
          websiteUrl: client.companyUrl,
          companyName: client.companyName,
          industry: client.companyIndustry,
          businessDescription: client.companyDescription,
          companyPhone: client.companyPhone,
          updatedAt: client.companyUpdatedAt,
          createdAt: client.companyCreatedAt,
          pointOfContact: {
            id: client.companyPOCId,
            firstName: client.companyPOCFirstName,
            lastName: client.companyPOCLastName,
            email: client.companyPOCEmail,
            phone: client.companyPOCPhone,
            propelAuthId: client.companyPOCPropelAuthId,
          },
          companyAddress: {
            id: client.companyAddressId,
            address1: client.companyAddress1,
            address2: client.companyAddress2,
            city: client.companyAddressCity,
            state: client.companyAddressState,
            zip: client.companyAddressZip,
          },
          role: client.userRole,
        } as any as clientTypeFull;
      });

      const userBusinessesMap: AccountState['userBusinessMap'] = {};
      conversion.forEach((client: clientTypeFull) => {
        userBusinessesMap[client.id] = client;
      });

      return {
        ...state,
        userBusinesses: conversion as Array<clientTypeFull>,
        userBusinessMap: userBusinessesMap,
      };
    });
    builder.addCase(fetchUserClientsByUserId.rejected, (state) => {
      return {
        ...state,
        userBusinesses: [],
        userBusinessMap: {},
      };
    });
    // builder.addCase(fetchAddressById.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     address: action.payload as userType,
    //   };
    // });
    // builder.addCase(fetchAddressById.rejected, (state) => {
    //   return {
    //     ...state,
    //     address: {},
    //   };
    // });
  },
});

// SELECTORS
// export const profileIdSelector = (state: RootState): string =>
//   state.account.profileId;
// export const emailSelector = (state: RootState) => state.account.email;
// export const usernameSelector = (state: RootState) => state.account.name;
// export const addressSelector = (state: RootState) => state.account.address;
export const propelAuthIdSelector = (state: RootState) =>
  state.account.propelAuthId;
export const userInfoSelector = (state: RootState) => state.account.userInfo;
export const userIdSelector = (state: RootState) => state.account.userId;
export const userBusinessesSelector = (state: RootState) =>
  state.account.userBusinesses;
export const userBusinessMapSelector = (state: RootState) =>
  state.account.userBusinessMap;

// EXPORTS
export const { setPropelAuthId } = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
