import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import { userBusinessesSelector } from '../../../store/account';

import HorizontalLine from '../../atoms/HorizontalLine';
import { ModalHeader, ModalSubHeader } from '../../atoms/ModalHeader';

import { adminGray, orangeMain } from '../../../constants/colors';

import { apiGeneralResponse } from '../../../types/api';
import { userClientType, userType, withTimeStamps } from '../../../types';
import { Loader } from '../../atoms/Loader';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const inputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
};

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

const autoCompleteSx = {
  width: '100%',
  margin: '10px 0 0 0',
  minWidth: 200,
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: userClientType['role'];
};

interface AddNewUserModalProps {}

export const AddNewUserModal: FC<AddNewUserModalProps> = () => {
  const userBusinesses = useSelector(userBusinessesSelector);
  const [fetchingUsers, setFetchingUsersLoading] = useState(false);
  const hasMoreThanOneBusiness = Object.keys(userBusinesses).length > 1;
  const [companySearchValue, setCompanySearchValue] = useState<string | null>(
    null
  );
  const [userSearchValue, setUserSearchValue] = useState<string | null>(null);
  const [usersDropdown, setUsersDropdown] = useState<
    Array<withTimeStamps<userType>>
  >([]);

  type companyUsersState = Array<{
    clientId: number;
    users: Array<withTimeStamps<userType>>;
  }>;
  const [companyUsers, setCompanyUsers] = useState<companyUsersState>([]);

  const params = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      role: 'user',
    },
  });

  useEffect(() => {
    if (companySearchValue?.trim().length) {
      const company = userBusinesses.find(
        (business) => business.companyName === companySearchValue
      );

      if (company) {
        const { id } = company;

        const alreadyFetched = companyUsers.find(
          (company) => company.clientId === id
        );

        if (alreadyFetched) {
          setUsersDropdown(alreadyFetched.users);
          return;
        }

        (async () => {
          try {
            setFetchingUsersLoading(true);

            const fetchedUsersResponse = (await axios.post(
              `/userClients/${id}/users?withUserInfo=true`
            )) as AxiosResponse<
              apiGeneralResponse<Array<withTimeStamps<userType>>>
            >;

            if (
              fetchedUsersResponse.data.success &&
              fetchedUsersResponse.data.result
            ) {
              const newCompanyToAdd = {
                clientId: id,
                users: fetchedUsersResponse.data.result,
              };

              setCompanyUsers([...companyUsers, newCompanyToAdd]);
              setUsersDropdown(newCompanyToAdd.users);
            }
          } catch (err) {
            console.log(err);
            summonFlashMessage('Error Fetching Users', 'error');
          } finally {
            setFetchingUsersLoading(false);
          }
        })();
      }
    }
  }, [companySearchValue, companyUsers, userBusinesses]);

  const onSubmit = async (data: FormValues) => {
    try {
      const { id } = params as { id: string };
      const { firstName, lastName, email, phone, role } = data;

      const userId = userSearchValue?.match(/\((\d+)\)/)?.[1] as string;

      const payload = userId
        ? {
            userId,
            clientId: id,
            role,
          }
        : {
            firstName,
            lastName,
            email,
            phone,
            clientId: id,
            role,
          };

      let response;

      if (userId) {
        response = await axios.post('/userClients/new', payload);
      } else {
        response = await axios.post('/users/new', payload);
      }

      if (response.data.success) {
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Adding User', 'error');
    }
  };

  return (
    <Container>
      <ModalHeader>New User</ModalHeader>
      <HorizontalLine width="100%" color={adminGray} />
      <Body>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.firstName}
          label={!!errors.firstName ? 'Error' : 'First Name'}
          defaultValue="First Name"
          helperText={!!errors.firstName && 'This Field is Required'}
          {...register('firstName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.lastName}
          label={!!errors.lastName ? 'Error' : `Last Name or "N/A"`}
          defaultValue="Last Name"
          helperText={!!errors.lastName && 'This Field is Required'}
          {...register('lastName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.email}
          label={!!errors.email ? 'Error' : 'Email'}
          defaultValue="Email"
          helperText={!!errors.email && 'This Field is Required'}
          {...register('email', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.phone}
          label={!!errors.phone ? 'Error' : 'Phone Number'}
          defaultValue="Phone Number"
          helperText={!!errors.phone && 'This Field is Required'}
          {...register('phone', { required: true })}
        />
        {hasMoreThanOneBusiness && (
          <>
            <ModalSubHeader style={{ margin: '5px 0' }}>
              Or Add Existing User From Different Company
            </ModalSubHeader>
            <Autocomplete
              disablePortal
              options={userBusinesses.map((business) => business.companyName)}
              sx={autoCompleteSx}
              renderInput={(params) => (
                <TextField {...params} label="Your Businesses" />
              )}
              value={companySearchValue}
              onChange={(event: any, newValue: string | null) =>
                setCompanySearchValue(newValue)
              }
            />
            {fetchingUsers ? (
              <Loader isLoading selfAlign />
            ) : (
              <Autocomplete
                disablePortal
                disabled={!usersDropdown.length}
                options={
                  usersDropdown.map(
                    (user) => `${user.firstName} ${user.lastName} (${user.id})`
                  ) || []
                }
                sx={autoCompleteSx}
                renderInput={(params) => (
                  <TextField {...params} label="Users" />
                )}
                value={userSearchValue}
                onChange={(event: any, newValue: string | null) =>
                  setUserSearchValue(newValue)
                }
              />
            )}
          </>
        )}
        <HorizontalLine width="100%" color={adminGray} />
        <ModalSubHeader style={{ margin: '5px 0' }}>Role</ModalSubHeader>
        <Autocomplete
          disablePortal
          defaultValue={'user'}
          onChange={(
            _,
            value: userClientType['role'] | undefined | null | string
          ) => {
            if (!value || !['owner', 'user', 'manager'].includes(value)) return;
            setValue('role', value as userClientType['role']);
          }}
          options={['user', 'manager', 'owner']}
          sx={autoCompleteSx}
          renderInput={(params) => <TextField {...params} label="Role" />}
        />
        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit(onSubmit)}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Confirm Add User
        </Button>
      </Body>
    </Container>
  );
};
