import React, { FC, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Stack from '@mui/material/Stack/Stack';
import Chip from '@mui/material/Chip/Chip';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';

import { ModalHeader, ModalSubHeader } from '../../atoms/ModalHeader';
import HorizontalLine from '../../atoms/HorizontalLine';

import { orangeMain } from '../../../constants/colors';

import { apiDBResponse } from '../../../types/api';
import { leadType } from '../../../types';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

interface NewTagModalProps {
  leadId?: string;
  clientId?: string;
  userId?: string;
  initialTags?: Array<string>;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

const inputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
};

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

export const NewTagModal: FC<NewTagModalProps> = ({
  leadId,
  userId,
  clientId,
  initialTags,
  closeModal,
  onSuccess,
}) => {
  const [tags, setTags] = useState<Array<string>>(initialTags || []);
  const [currentTagInputValue, setCurrentTagInputValue] = useState('');

  const handleTagAddition = () => {
    if (currentTagInputValue) {
      setTags([...tags, currentTagInputValue]);
    }

    setCurrentTagInputValue('');

    // Hardcoded bad practice but it works so fuck it
    (document.getElementById('tagInput') as any).value = '';
  };

  const handleTagDelete = (val: string) => {
    const newTags = tags.filter((tag) => tag !== val);
    setTags(newTags);
  };

  const handleAddTags = async () => {
    const isLeadUpdate = !!leadId;
    const isUserUpdate = !!userId;
    const isClientUpdate = !!clientId;

    if (isLeadUpdate) {
      const payload = {
        tags,
      };

      try {
        const leadData = await axios.patch<apiDBResponse<leadType>>(
          `/leads/${leadId}`,
          payload
        );

        if (!leadData.data.success || !leadData.data.result) {
          summonFlashMessage('Error Adding Tag', 'error');
          console.log('Error updating lead');
          closeModal();
          return;
        }

        await onSuccess();
        summonFlashMessage('Success Adding Tag', 'success');
      } catch (err) {
        console.error(err);
        summonFlashMessage('Error Adding Tag', 'error');
      } finally {
        closeModal();
      }
    }

    if (isUserUpdate) {
      console.log('TODO, HANDLE USER');
      return;
    }

    if (isClientUpdate) {
      console.log('TODO, HANDLE CLIENT');
      return;
    }

    console.log('UNLOGGED');
    return;
    // Dispatch Update Lead by Id
  };

  return (
    <Container>
      <ModalHeader>Add New Tags</ModalHeader>
      <HorizontalLine />
      <ModalSubHeader>Tags</ModalSubHeader>
      <ModalBody>
        <Stack direction="row" spacing={1}>
          {tags.map((tag) => (
            <Chip
              label={tag}
              variant="outlined"
              onDelete={() => handleTagDelete(tag)}
            />
          ))}
        </Stack>
        <TextField
          margin="dense"
          id="tagInput"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          onChange={(e) => setCurrentTagInputValue(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          onClick={handleTagAddition}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 5px 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Add Tag
        </Button>
        <HorizontalLine />
        <Button
          variant="contained"
          size="large"
          onClick={handleAddTags}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Confirm {initialTags?.length ? 'Update Tags' : 'Add Tags'}
        </Button>
      </ModalBody>
    </Container>
  );
};
