import {
  Add as AddIcon,
  CalendarMonth as CalendarMonthIcon,
  Call as CallIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  Mail as MailIcon,
  Person as PersonIcon,
} from '@mui/icons-material';

import {
  Button,
  Paper,
  Stack,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import { useAppDispatch } from '../store';
import { userBusinessMapSelector } from '../store/account';
import {
  ageGenerationsSelector,
  leadStagesSelector,
  updateLeadById,
} from '../store/leads';

import { Address } from '../components/molecules/Address';
import { Estimate } from '../components/molecules/Estimate';
import { Note } from '../components/molecules/Note';
import { Tag } from '../components/molecules/Tag';

// Modals
import { DeleteEstimateModal } from '../components/molecules/modals/DeleteEstimateModal';
import { DeleteLeadModal } from '../components/molecules/modals/DeleteLeadModal';
import { DeleteNoteModal } from '../components/molecules/modals/DeleteNoteModal';
import { DeleteTagModal } from '../components/molecules/modals/DeleteTagModal';
import { EditAddressModal } from '../components/molecules/modals/EditAddressModal';
import { EditEstimateModal } from '../components/molecules/modals/EditEstimateModal';
import { EditLeadModal } from '../components/molecules/modals/EditLeadModal';
import { EditNoteModal } from '../components/molecules/modals/EditNoteModal';
import { LifeCycleChangeModal } from '../components/molecules/modals/LifeCycleChangeModal';
import { NewEstimateModal } from '../components/molecules/modals/NewEstimateModal';
import { NewNoteModal } from '../components/molecules/modals/NewNoteModal';
import { NewTagModal } from '../components/molecules/modals/NewTagModal';

import { InternalTag } from '../components/atoms/InternalTag';
import { Loader } from '../components/atoms/Loader';

import { useModal } from '../hooks/UseModal';

import {
  deleteRed,
  leadStageColorsSolid,
  orangeMain,
} from '../constants/colors';

import { summonFlashMessage } from '../helpers/flashMessage';
import {
  addressType,
  estimateType,
  getLeadByIdFull,
  leadStageType,
  leadType,
  noteType,
  withTimeStamps,
} from '../types';
import { apiGeneralResponse } from '../types/api';

const cardSx = {
  '&.MuiPaper-root': {
    padding: '30px 15px',
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0',
    width: '90%',
    backgroundColor: 'white',
  },
};

const Section = styled.div<{ transition?: boolean; disableColumn?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  ${({ transition }) => transition && 'width:100%;'}

  @media (max-width: 500px) {
    flex-wrap: wrap;
    ${({ disableColumn }) =>
      !disableColumn && 'flex-direction: column-reverse;'}
    ${({ disableColumn }) => disableColumn && 'justify-content: flex-start;'}
  }
`;

const ContactSection = styled.div`
  display: flex;
  align-items: center;
`;

const SectionBody = styled.div<{
  transition?: boolean;
  controlledWidth?: boolean;
  center?: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ transition }) => transition && 'width: 100%;'}
  ${({ controlledWidth }) =>
    controlledWidth && `@media (max-width: 500px) { width: 100%; };`}
  ${({ controlledWidth, center }) =>
    controlledWidth &&
    center &&
    `@media (max-width: 500px) { align-items: center; };`}
`;

const NameHeader = styled.h1`
  margin: 0;
  display: flex;

  @media (max-width: 500px) {
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 14px;
  color: gray;
`;

const ContactSpan = styled.span`
  padding-left: 5px;
  display: flex;
  overflow-wrap: anywhere;
`;

const SubHeader = styled.h2`
  margin-top: 0;
`;

const HeaderSectionWithButton = styled.div<{ transition?: boolean }>`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;

  ${({ transition }) =>
    !transition &&
    `
    align-items: center;
  `}

  ${({ transition }) =>
    transition &&
    `
    justify-content: space-between;
  `}

  h2 {
    margin: 0;
  }
`;

const buttonSx = (isDelete?: boolean) => ({
  color: 'black',
  backgroundColor: isDelete ? deleteRed : orangeMain,
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'italic',
  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
    boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
    backgroundColor: isDelete ? deleteRed : orangeMain,
  },

  '@media (max-width: 500px)': {
    margin: isDelete ? '1rem 0 0 0' : '0 0 1rem 0',
  },
});

const iconButtonSx = () => ({
  color: 'black',
  backgroundColor: 'transparent',
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'italic',
  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
  },
});

const tabSx = (id: number) => ({
  border: 'none',
  padding: '0px 10px',

  '&.MuiTab-textColorPrimary': {
    color: 'black',
  },

  '&.Mui-selected': {
    backgroundColor: leadStageColorsSolid[id],
  },
});

const tabsSx: SxProps<Theme> = {
  '.MuiTabs-indicator': { backgroundColor: orangeMain },
};

const colorSx: SxProps<Theme> = { color: orangeMain };

interface LeadPageProps {}

export const LeadPage: FC<LeadPageProps> = () => {
  const params = useParams();
  const { leadId } = params;

  const [openEditLeadModal, EditLeadModalShell, forceCloseEditLeadModal] =
    useModal();
  const [openDeleteLeadModal, DeleteLeadModalShell, forceCloseDeleteLeadModal] =
    useModal();
  const [openDeleteNoteModal, DeleteNoteModalShell, forceCloseDeleteNoteModal] =
    useModal();
  const [
    openDeleteEstimateModal,
    DeleteEstimateModalShell,
    forceCloseDeleteEstimateModal,
  ] = useModal();
  const [openNewTagModal, NewTagModalShell, forceCloseNewTagModal] = useModal();
  const [openNewNoteModal, NewNoteModalShell, forceCloseNewNoteModal] =
    useModal();
  const [openEditNoteModal, EditNoteModalShell, forceCloseEditNoteModal] =
    useModal();
  const [
    openNewEstimateModal,
    NewEstimateModalShell,
    forceCloseNewEstimateModal,
  ] = useModal();
  const [
    openEditEstimateModal,
    EditEstimateModalShell,
    forceCloseEditEstimateModal,
  ] = useModal();
  const [openDeleteTagModal, DeleteTagModalShell, forceCloseDeleteTagModal] =
    useModal();
  const [
    openLifeCycleChangeModal,
    LifeCycleChangeModalShell,
    forceCloseLifeCycleChangeModal,
  ] = useModal();
  const [
    openServiceLocationModal,
    EditServiceLocationModalShell,
    forceCloseServiceLocationModal,
  ] = useModal();
  const [
    openBusinessLocationModal,
    EditBusinessLocationModalShell,
    forceCloseBusinessLocationModal,
  ] = useModal();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { width, ref } = useResizeDetector();
  const shouldTransition = width && width >= 500;

  const leadStageInformation = useSelector(leadStagesSelector);
  const userBusinessesMap = useSelector(userBusinessMapSelector);
  const ageGenerations = useSelector(ageGenerationsSelector);

  // Tab Ordering
  const orderedStages = [...leadStageInformation].sort((a, b) =>
    a.sortOrder < b.sortOrder ? -1 : 1
  );

  // Lead Page State Info
  const [isLeadLoading, setIsLeadLoading] = useState<boolean>(false);
  const [leadInformation, setLeadInformation] = useState<
    Partial<getLeadByIdFull>
  >({});
  // What Life Cycle can be changed to
  const [toBelifeCycleValue, setToBeLifeCycleValue] = useState<
    undefined | leadStageType
  >(undefined);

  // Tab Value
  const [lifeCycleValue, setLifeCycleValue] = useState<undefined | number>(
    undefined
  );
  const [leadType, setLeadType] = useState<string>('');

  // To be deleted/edited options
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<
    (noteType & { owner: string }) | undefined
  >(undefined);
  const [noteToBeEdited, setNoteToBeEdited] = useState<
    (noteType & { owner: string }) | undefined
  >(undefined);
  const [estimateToBeEdited, setEstimateToBeEdited] = useState<
    { estimate: estimateType; owner: string } | undefined
  >(undefined);
  const [estimateToBeDeleted, setEstimateToBeDeleted] = useState<
    { estimate: estimateType; owner: string } | undefined
  >(undefined);
  const [tagToBeRemoved, setTagToBeRemoved] = useState('');

  const fetchLeadById = async () => {
    try {
      const leadResponse = await axios.get<apiGeneralResponse<getLeadByIdFull>>(
        `/leads/${leadId}?withFullInfo=true`
      );

      if (leadResponse.data.success && leadResponse.data) {
        setLeadInformation(leadResponse.data.result as getLeadByIdFull);

        // Set the lifecycle value
        setLifeCycleValue(
          leadResponse.data.result?.lifeCycle?.id &&
            leadResponse.data.result?.lifeCycle?.sortOrder
        );

        // Set the lead type
        setLeadType(
          leadResponse.data.result?.lifeCycle?.type
            ? leadResponse.data.result?.lifeCycle?.type
            : 'Lead'
        );
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Fetching Lead', 'error');
    }
  };

  // Fetching Lead Information
  useEffect(() => {
    setIsLeadLoading(true);
    if (!leadId || typeof +leadId !== 'number') {
      navigate(-1);
      return;
    }

    (async () => {
      try {
        const leadResponse = await axios.get<
          apiGeneralResponse<getLeadByIdFull>
        >(`/leads/${leadId}?withFullInfo=true`);

        if (leadResponse.data.success && leadResponse.data) {
          setLeadInformation(leadResponse.data.result as getLeadByIdFull);

          // Set the lifecycle value
          setLifeCycleValue(
            leadResponse.data.result?.lifeCycle?.id &&
              leadResponse.data.result?.lifeCycle?.sortOrder
          );

          // Set the lead type
          setLeadType(
            leadResponse.data.result?.lifeCycle?.type
              ? leadResponse.data.result?.lifeCycle?.type
              : 'Lead'
          );
        }

        setIsLeadLoading(false);
      } catch (err) {
        console.log(err);
        summonFlashMessage('Error Fetching Lead', 'error');
      }
    })();
  }, [leadId, navigate, dispatch, leadInformation?.lifeCycle?.id]);

  const handleReviewRequest = async () => {
    const updatedLead = {
      leadId: `${leadInformation?.lead?.id ?? '1'}`,
      leadData: {
        ...leadInformation?.lead,
        reviewRequested: true,
      },
    };

    try {
      await dispatch(updateLeadById(updatedLead));
      await fetchLeadById();
    } catch (err) {
      console.error(err);
    }
  };
  // Handle the lifecycle changes
  const handleLifeCycleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setToBeLifeCycleValue(orderedStages[newValue]);
    openLifeCycleChangeModal();
  };

  const handleLifeCycleChangeMobile = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setToBeLifeCycleValue(orderedStages[+value]);
    openLifeCycleChangeModal();
  };

  const handleTagDelete = async (tag: string) => {
    setTagToBeRemoved(tag);
    openDeleteTagModal();
  };

  const handleNoteDelete = async (note: noteType) => {
    const owner = `${leadInformation.lead?.firstName} ${leadInformation.lead?.lastName}`;

    const noteToBeDeleted = {
      ...note,
      owner,
    };

    setNoteToBeDeleted(noteToBeDeleted);
    openDeleteNoteModal();
  };

  const handleNoteEdit = async (note: noteType) => {
    const owner = `${leadInformation.lead?.firstName} ${leadInformation.lead?.lastName}`;

    const noteToBeEdited = {
      ...note,
      owner,
    };

    setNoteToBeEdited(noteToBeEdited);
    openEditNoteModal();
  };

  const handleEstimateEdit = async (estimate: estimateType) => {
    const owner = `${leadInformation.lead?.firstName} ${leadInformation.lead?.lastName}`;

    const estimateToBeEdited = {
      estimate,
      owner,
    };

    setEstimateToBeEdited(estimateToBeEdited);
    openEditEstimateModal();
  };

  const handleEstimateDelete = async (estimate: estimateType) => {
    const owner = `${leadInformation.lead?.firstName} ${leadInformation.lead?.lastName}`;

    const estimateToBeDeleted = {
      estimate,
      owner,
    };

    setEstimateToBeDeleted(estimateToBeDeleted);
    openDeleteEstimateModal();
  };

  // Ensure Page is ready to render
  const isPageLoading = isLeadLoading || !leadInformation;

  const hasMoreThanOneBusiness = Object.keys(userBusinessesMap).length > 1;
  const companyName =
    userBusinessesMap[leadInformation?.lead?.clientId as number]?.companyName ||
    '';

  const ageGeneration = ageGenerations.find(
    (ageGeneration) => ageGeneration.id === leadInformation.lead?.age
  );

  const ageGenerationNamed = `${ageGeneration?.generation} ${ageGeneration?.yearStart}-${ageGeneration?.yearEnd}`;

  return (
    <Paper sx={cardSx} ref={ref}>
      {/* MODAL SHELLS */}
      <EditServiceLocationModalShell
        contents={
          <EditAddressModal
            closeModal={forceCloseServiceLocationModal}
            leadId={leadInformation.lead?.id as number}
            type="service"
            addressInformation={
              leadInformation?.serviceLocation as withTimeStamps<addressType>
            }
            onSuccess={fetchLeadById}
          />
        }
      />
      <EditBusinessLocationModalShell
        contents={
          <EditAddressModal
            closeModal={forceCloseBusinessLocationModal}
            leadId={leadInformation.lead?.id as number}
            type="business"
            addressInformation={
              leadInformation?.address as withTimeStamps<addressType>
            }
            onSuccess={fetchLeadById}
          />
        }
      />
      <EditNoteModalShell
        contents={
          <EditNoteModal
            adminOnly={noteToBeEdited?.adminOnly as boolean}
            note={noteToBeEdited?.note as string}
            noteId={noteToBeEdited?.id as number}
            target={noteToBeEdited?.owner as string}
            closeModal={forceCloseEditNoteModal}
            onSuccess={fetchLeadById}
          />
        }
      />
      <EditEstimateModalShell
        contents={
          <EditEstimateModal
            closeModal={forceCloseEditEstimateModal}
            estimateInformation={estimateToBeEdited?.estimate as estimateType}
            // target={estimateToBeEdited?.owner as string}
            onSuccess={fetchLeadById}
          />
        }
      />

      <DeleteLeadModalShell
        contents={
          <DeleteLeadModal
            closeModal={forceCloseDeleteLeadModal}
            leadInformation={leadInformation}
            onSuccess={() => navigate(-1)}
          />
        }
      />
      <EditLeadModalShell
        contents={
          <EditLeadModal
            closeModal={forceCloseEditLeadModal}
            leadInformation={leadInformation}
            onSuccess={fetchLeadById}
          />
        }
      />
      <DeleteNoteModalShell
        contents={
          <DeleteNoteModal
            note={noteToBeDeleted?.note as string}
            noteId={noteToBeDeleted?.id as number}
            target={noteToBeDeleted?.owner as string}
            closeModal={forceCloseDeleteNoteModal}
            leadId={leadId}
            onSuccess={fetchLeadById}
          />
        }
      />
      <DeleteTagModalShell
        contents={
          <DeleteTagModal
            tag={tagToBeRemoved}
            target={`${leadInformation.lead?.firstName} ${leadInformation.lead?.lastName}`}
            leadId={leadId}
            closeModal={forceCloseDeleteTagModal}
            tags={leadInformation?.lead?.tags || []}
            onSuccess={fetchLeadById}
          />
        }
      />
      <NewTagModalShell
        contents={
          <NewTagModal
            closeModal={forceCloseNewTagModal}
            leadId={leadId}
            initialTags={leadInformation?.lead?.tags}
            onSuccess={fetchLeadById}
          />
        }
      />
      <NewNoteModalShell
        contents={
          <NewNoteModal
            closeModal={forceCloseNewNoteModal}
            onSuccess={fetchLeadById}
            clientId={leadInformation.lead?.clientId as number}
            leadId={leadId}
          />
        }
      />
      <NewEstimateModalShell
        contents={
          <NewEstimateModal
            closeModal={forceCloseNewEstimateModal}
            onSuccess={fetchLeadById}
            clientId={leadInformation.lead?.clientId as number}
            leadId={leadId}
          />
        }
      />
      <DeleteEstimateModalShell
        contents={
          <DeleteEstimateModal
            estimate={estimateToBeDeleted?.estimate as estimateType}
            target={estimateToBeDeleted?.owner as string}
            closeModal={forceCloseDeleteEstimateModal}
            onSuccess={fetchLeadById}
          />
        }
      />
      <LifeCycleChangeModalShell
        contents={
          <LifeCycleChangeModal
            lead={leadInformation.lead as withTimeStamps<leadType>}
            oldStage={
              leadInformation.lifeCycle as withTimeStamps<leadStageType>
            }
            onSuccess={fetchLeadById}
            newStage={toBelifeCycleValue as leadStageType}
            closeModal={forceCloseLifeCycleChangeModal}
          />
        }
      />
      {isPageLoading ? (
        <Loader selfAlign isLoading />
      ) : (
        <>
          {/* NAME HEADER SECTION */}
          <Section>
            <SectionBody transition={!shouldTransition}>
              <NameHeader>
                {`${leadInformation?.lead?.firstName} ${leadInformation?.lead?.lastName}`}
                <Button
                  size="medium"
                  sx={{ padding: '0' }}
                  onClick={openEditLeadModal}
                >
                  <EditIcon sx={iconButtonSx()} />
                </Button>
              </NameHeader>
              <IDSpan>
                id: <code>{leadInformation?.lead?.id}</code>
              </IDSpan>

              <div
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {leadInformation.lead?.gender && (
                  <ContactSection>
                    <PersonIcon sx={colorSx} />
                    <ContactSpan>{leadInformation.lead?.gender}</ContactSpan>
                  </ContactSection>
                )}
                {ageGeneration && (
                  <ContactSection>
                    <CalendarMonthIcon sx={colorSx} />
                    <ContactSpan>{ageGenerationNamed}</ContactSpan>
                  </ContactSection>
                )}
                <ContactSection>
                  <MailIcon sx={colorSx} />
                  <ContactSpan>{leadInformation?.lead?.email}</ContactSpan>
                </ContactSection>
                <ContactSection>
                  <CallIcon sx={colorSx} />
                  <ContactSpan>{leadInformation?.lead?.phone}</ContactSpan>
                </ContactSection>

                {leadInformation?.lifeCycle?.type === 'Customer' &&
                  !leadInformation?.lead?.reviewRequested && (
                    <Button
                      variant="contained"
                      size="medium"
                      sx={buttonSx()}
                      onClick={handleReviewRequest}
                    >
                      Request Review
                    </Button>
                  )}
                {leadInformation?.lifeCycle?.type === 'Customer' &&
                  leadInformation?.lead?.reviewRequested && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CheckIcon sx={{ color: orangeMain }} />
                      <p style={{ margin: '0' }}>
                        Review Requested On{' '}
                        {dayjs(leadInformation?.lead?.reviewRequestedAt).format(
                          'MM/DD/YYYY'
                        )}
                      </p>
                    </div>
                  )}

                {companyName && hasMoreThanOneBusiness && (
                  <InternalTag
                    style={{ marginTop: '1rem', textAlign: 'center' }}
                  >
                    {companyName}
                  </InternalTag>
                )}
              </div>
            </SectionBody>
            <SectionBody>
              {shouldTransition && (
                <Button
                  variant="contained"
                  size="medium"
                  sx={buttonSx(true)}
                  onClick={openDeleteLeadModal}
                >
                  Delete {leadType}
                </Button>
              )}
            </SectionBody>
          </Section>
          {/* LIFE CYCLE SECTION */}
          <Section transition={!shouldTransition}>
            <SectionBody transition={!shouldTransition}>
              <SubHeader>Stage</SubHeader>
              {typeof lifeCycleValue === 'number' ? (
                shouldTransition ? (
                  <Tabs
                    sx={tabsSx}
                    // Subtracted 1 from the value to account for the fact that the array is 0 indexed
                    value={lifeCycleValue - 1}
                    onChange={handleLifeCycleChange}
                    orientation="horizontal"
                    centered
                  >
                    {orderedStages.map((stage) => (
                      <Tab
                        sx={{ ...tabSx(stage.id) }}
                        key={stage.id}
                        label={stage.type}
                      />
                    ))}
                  </Tabs>
                ) : (
                  <Select
                    labelId="lifecycle-select-label"
                    id="lifecycle-select"
                    value={lifeCycleValue - 1}
                    label="Stage"
                    onChange={handleLifeCycleChangeMobile}
                  >
                    {orderedStages.map((stage) => (
                      <MenuItem key={stage.id} value={stage.sortOrder - 1}>
                        {stage.type}
                      </MenuItem>
                    ))}
                  </Select>
                )
              ) : (
                <Loader isLoading selfAlign />
              )}
            </SectionBody>
          </Section>
          {/* SERVICE LOCATION SECTION */}
          <Section>
            <SectionBody transition>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Service Location</SubHeader>
                <Button
                  size="medium"
                  sx={{ padding: '0' }}
                  onClick={openServiceLocationModal}
                >
                  <EditIcon sx={iconButtonSx()} />
                </Button>
              </HeaderSectionWithButton>
              {leadInformation?.serviceLocation ? (
                <Address address={leadInformation?.serviceLocation || {}} />
              ) : (
                <Typography>No Service Location Found</Typography>
              )}
            </SectionBody>
          </Section>
          {/* Business LOCATION SECTION */}
          <Section>
            <SectionBody transition>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Business Location</SubHeader>
                <Button
                  size="medium"
                  sx={{ padding: '0' }}
                  onClick={openBusinessLocationModal}
                >
                  <EditIcon sx={iconButtonSx()} />
                </Button>
              </HeaderSectionWithButton>
              {leadInformation?.address ? (
                <Address address={leadInformation?.address || {}} />
              ) : (
                <Typography>No Business Location Found</Typography>
              )}
            </SectionBody>
          </Section>
          {/* TAGS SECTION */}
          <Section>
            <SectionBody transition>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Tags</SubHeader>
                <Button
                  size="medium"
                  sx={{ padding: '0' }}
                  onClick={openNewTagModal}
                >
                  <AddIcon sx={iconButtonSx()} />
                </Button>
              </HeaderSectionWithButton>
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {leadInformation?.lead?.tags?.length ? (
                  leadInformation?.lead?.tags?.map((tag, i) => (
                    <Tag
                      key={`${tag}-${i}`}
                      tag={tag}
                      onDelete={handleTagDelete}
                    />
                  ))
                ) : (
                  <p>No tags found</p>
                )}
              </Stack>
            </SectionBody>
          </Section>
          {/* NOTES SECTION */}
          <Section>
            <SectionBody transition>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Notes</SubHeader>
                <Button
                  size="medium"
                  sx={{ padding: '0' }}
                  onClick={openNewNoteModal}
                >
                  <AddIcon sx={iconButtonSx()} />
                </Button>
              </HeaderSectionWithButton>
              <SectionBody controlledWidth center>
                {leadInformation?.notes?.length ? (
                  leadInformation?.notes
                    ?.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
                    .map((note, i) => (
                      <Note
                        key={`${note.id}`}
                        note={note}
                        onNoteDelete={() => handleNoteDelete(note)}
                        onNoteEdit={() => handleNoteEdit(note)}
                      />
                    ))
                ) : (
                  <p>No notes found</p>
                )}
              </SectionBody>
            </SectionBody>
          </Section>
          <Section>
            <SectionBody transition>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Estimates</SubHeader>
                <Button
                  size="medium"
                  sx={{ padding: '0' }}
                  onClick={openNewEstimateModal}
                >
                  <AddIcon sx={iconButtonSx()} />
                </Button>
              </HeaderSectionWithButton>
              <SectionBody controlledWidth center>
                {leadInformation?.estimates?.length ? (
                  leadInformation?.estimates
                    ?.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
                    .map((estimate, i) => (
                      <Estimate
                        key={`${estimate.id}`}
                        estimate={estimate}
                        onEstimateDelete={() => handleEstimateDelete(estimate)}
                        onEstimateEdit={() => handleEstimateEdit(estimate)}
                      />
                    ))
                ) : (
                  <p>No estimates found</p>
                )}
              </SectionBody>
            </SectionBody>
          </Section>
          {!shouldTransition && (
            <Button
              variant="contained"
              size="medium"
              sx={buttonSx(true)}
              onClick={openDeleteLeadModal}
            >
              Delete {leadType}
            </Button>
          )}
        </>
      )}
    </Paper>
  );
};
