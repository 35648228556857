import axios, { AxiosResponse } from 'axios';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import { userBusinessesSelector } from '../store/account';

import { UserInfoCard } from '../components/molecules/UserInfoCard';
import { AddNewUserModal } from '../components/molecules/modals/AddNewUserModal';
import { EditCompanyInformationModal } from '../components/molecules/modals/EditCompanyInformationModal';

import { Loader } from '../components/atoms/Loader';

import { orangeMain } from '../constants/colors';
import { useModal } from '../hooks/UseModal';
import {
  clientTypeFull,
  getAllClientsInfoWithIdsResults,
  userType,
} from '../types';
import { summonFlashMessage } from '../helpers/flashMessage';
import { apiDBResponse } from '../types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const NameHeader = styled.h1`
  margin: 0;
`;

const SubHeader = styled.h2`
  margin-top: 0;
  font-size: 24px;

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

const InfoHeader = styled.h3`
  margin: 1rem 0 6px 0;
  font-size: 18px;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const Section = styled.div<{ first?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 60%;
  padding: 10px;

  ${({ first }) =>
    first &&
    `
    min-width: 35%;
    @media (max-width: 1000px) {
      min-width: 90%;
    }
  `}
`;

const HeaderSectionWithButton = styled.div<{
  transition?: boolean;
  first?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  ${({ first, transition }) =>
    first &&
    `
    flex-direction: column;

    button {
      width: 100%;
    }

    @media (max-width: 1000px) {
      flex-direction: ${transition ? 'column' : 'row'};

      button {
        width: ${transition ? '100%' : 'auto'};
      }
    }
  `}

  ${({ transition }) =>
    !transition &&
    `
    align-items: center;
  `}

  ${({ transition }) =>
    transition &&
    `
    flex-direction: column;
  `}

  h2 {
    margin-bottom: 1rem;
  }
`;

const ContentSpan = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

interface BusinessManagementPageProps {}

const cardSx = (withCursor?: boolean) => ({
  '&.MuiPaper-root': {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    transition: 'all 0.25s ease-in-out',
    cursor: withCursor ? 'pointer' : 'default',

    ':hover': {
      transform: 'scale(1.02, 1.01)',
    },
  },
});

export const BusinessManagementPage: FC<BusinessManagementPageProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { width, ref } = useResizeDetector();
  const businessInformation = useSelector(userBusinessesSelector);
  const [summonNewUserModal, NewUserModalShell, forceCloseNewUserModal] =
    useModal();
  const [
    summonEditCompanyModal,
    EditCompanyModalShell,
    forceCloseEditCompanyModal,
  ] = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [isBusinessUsersLoading, setIsBusinessUsersLoading] = useState(false);

  const shouldTransition = width && width >= 600;

  const { id } = params as { id: string };

  const navigateToLeads = () => navigate(`/leads?clientId=${id}`);

  let foundBusinessInformation = useRef(
    businessInformation.find((business) => business.id === +id) || {}
  );

  let foundBusinessUsers = useRef(
    [] as Array<userType & { clientId: number; role?: string }>
  );

  useEffect(() => {
    if (!Object.keys(foundBusinessInformation.current).length) {
      setIsLoading(true);
      (async () => {
        try {
          const clientInfoResponse = await axios.get(
            `/clients/${id}?withFullInfo=true`
          );

          if (!Object.keys(clientInfoResponse.data).length) {
            summonFlashMessage('No Company Found By Id', 'warning');
            navigate(-1);
          }

          foundBusinessInformation.current = clientInfoResponse.data.result;
        } catch (error: any) {
          if (error.response.status === 404) {
            summonFlashMessage('No Company Found By Id', 'warning');
            navigate(-1);
          } else if (error.response.status === 403) {
            summonFlashMessage('Unauthorized', 'error');
            navigate(-1);
          } else {
            console.error(error);
            summonFlashMessage('Error Fetching Company Information', 'error');
            navigate(-1);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [id, navigate]);

  useEffect(() => {
    if (!foundBusinessUsers.current.length) {
      setIsBusinessUsersLoading(true);
      (async () => {
        const users = await axios.post(
          `/userClients/${id}/users?withUserInfo=true`
        );
        const output = users.data.result as Array<
          userType & { clientId: number; role?: string }
        >;
        foundBusinessUsers.current = output;
        setIsBusinessUsersLoading(false);
      })();
    }
  }, [id]);

  const handleUserClick = (userId: number) =>
    navigate(`/user/${userId}?clientId=${id}`);

  const {
    websiteURL,
    companyName,
    industry,
    businessDescription,
    companyPhone,
    companyAddress,
    pointOfContact,
  } = foundBusinessInformation.current as clientTypeFull;

  const hasValidInfo =
    !!foundBusinessInformation &&
    Object.keys(foundBusinessInformation.current).length &&
    companyAddress &&
    Object.keys(companyAddress).length &&
    !isLoading;

  return (
    <Container ref={ref}>
      <NewUserModalShell
        contents={<AddNewUserModal />}
        onClose={forceCloseNewUserModal}
      />
      <EditCompanyModalShell
        contents={
          <EditCompanyInformationModal
            closeModal={forceCloseEditCompanyModal}
            onSuccess={() => window.location.reload()}
            company={foundBusinessInformation.current as clientTypeFull}
            users={foundBusinessUsers.current}
          />
        }
      />
      {!hasValidInfo ? (
        <Loader selfAlign isLoading={isLoading} />
      ) : (
        <>
          <HeaderSectionWithButton transition={!shouldTransition}>
            <NameHeader style={{ margin: '2rem 0' }}>{companyName}</NameHeader>
            <Button
              onClick={navigateToLeads}
              sx={{
                color: 'black',
                backgroundColor: orangeMain,
                fontWeight: 700,
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'italic',
                transition: 'all 0.25s ease-in-out',

                '&:hover': {
                  transform: 'scale(1.02, 1.01)',
                  boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                  backgroundColor: orangeMain,
                },
              }}
            >
              See Company Leads
            </Button>
          </HeaderSectionWithButton>
          <ContentContainer>
            <Section first>
              <HeaderSectionWithButton transition={!shouldTransition} first>
                <SubHeader>Company Information</SubHeader>
                <Button
                  onClick={summonEditCompanyModal}
                  sx={{
                    color: 'black',
                    backgroundColor: orangeMain,
                    fontWeight: 700,
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'italic',
                    transition: 'all 0.25s ease-in-out',

                    '&:hover': {
                      transform: 'scale(1.02, 1.01)',
                      boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                      backgroundColor: orangeMain,
                    },
                  }}
                >
                  Edit Company Information
                </Button>
              </HeaderSectionWithButton>
              <Paper elevation={2} sx={cardSx()}>
                <InfoHeader style={{ marginTop: 0 }}>Company</InfoHeader>
                <ContentSpan>{websiteURL}</ContentSpan>
                <ContentSpan>{companyPhone}</ContentSpan>
                <InfoHeader>Industry</InfoHeader>
                <ContentSpan>{industry}</ContentSpan>
                <InfoHeader>Description</InfoHeader>
                <ContentSpan>{businessDescription}</ContentSpan>
                <InfoHeader>Point Of Contact</InfoHeader>
                <ContentSpan>
                  {pointOfContact.firstName} {pointOfContact.lastName}
                </ContentSpan>
                <ContentSpan>{pointOfContact.phone}</ContentSpan>
                <ContentSpan>{pointOfContact.email}</ContentSpan>
                <InfoHeader>Address</InfoHeader>
                <ContentSpan>{companyAddress.address1}</ContentSpan>
                {companyAddress.address2 && (
                  <ContentSpan>{companyAddress.address2}</ContentSpan>
                )}
                <ContentSpan>{`${companyAddress.city}, ${companyAddress.state} ${companyAddress.zip}`}</ContentSpan>
              </Paper>
            </Section>
            <Section>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Users</SubHeader>
                <Button
                  onClick={summonNewUserModal}
                  sx={{
                    color: 'black',
                    backgroundColor: orangeMain,
                    fontWeight: 700,
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'italic',
                    transition: 'all 0.25s ease-in-out',

                    '&:hover': {
                      transform: 'scale(1.02, 1.01)',
                      boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                      backgroundColor: orangeMain,
                    },
                  }}
                >
                  Add User
                </Button>
              </HeaderSectionWithButton>
              <Loader selfAlign isLoading={isBusinessUsersLoading}>
                {foundBusinessUsers.current.map((user) => (
                  <Paper
                    key={user.id}
                    elevation={2}
                    sx={cardSx(true)}
                    onClick={() => handleUserClick(user.id)}
                  >
                    <Container style={{ width: '98%' }}>
                      <UserInfoCard userInfo={user} />
                    </Container>
                  </Paper>
                ))}
              </Loader>
            </Section>
          </ContentContainer>
        </>
      )}
    </Container>
  );
};
