import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import { leadSnareAdminSelector } from '../../store/site';
import { deleteRed, orangeMain } from '../../constants/colors';
import { noteType } from '../../types';
import { InternalTag } from '../atoms/InternalTag';

interface NoteProps {
  note: Partial<noteType>;
  onNoteDelete: () => void;
  onNoteEdit: () => void;
}

const noteSx = {
  '&.MuiPaper-root': {
    margin: '10px',
  },
};

const buttonSx = (isDelete?: boolean) => ({
  color: 'black',
  backgroundColor: isDelete ? deleteRed : orangeMain,
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
    boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
    backgroundColor: isDelete ? deleteRed : orangeMain,
  },

  '@media (max-width: 500px)': {
    marginBottom: '1rem',
  },
});

const cardSx = {
  '&.MuiPaper-root': {
    marginBottom: '1rem',
    width: '100%',
  },
};

export const Note: FC<NoteProps> = ({ note, onNoteDelete, onNoteEdit }) => {
  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);
  const handleNoteDelete = () => onNoteDelete();
  const handleNoteEdit = () => onNoteEdit();

  function parseNotes(str: string | undefined) {
    if (!str) return null;
    const otherNotesStr = 'Other Notes: ';
    if (str.includes(otherNotesStr)) {
      const splitStr = str.split(otherNotesStr);
      const obj = JSON.parse(splitStr[1]);
      return {
        notes: splitStr[0],
        extraProps: obj,
      };
    } else {
      return null;
    }
  }

  const parsedNote = parseNotes(note.note);

  return (
    <Card sx={cardSx} raised>
      {isLeadSnareAdmin && note.adminOnly && (
        <div style={{ margin: '5px 0 0 5px' }}>
          <InternalTag>LeadSnare Admin Only Note</InternalTag>
        </div>
      )}
      <Paper sx={noteSx} elevation={0}>
        {parsedNote ? (
          <>
            {parsedNote.notes}
            <ul>
              {Object.entries(parsedNote.extraProps).map(
                ([key, value]: [string, any]) => (
                  <li key={key}>
                    {key}: {value ?? value}
                  </li>
                )
              )}
            </ul>
          </>
        ) : (
          note.note
        )}
      </Paper>
      <CardActions>
        <Button sx={buttonSx()} size="small" onClick={handleNoteEdit}>
          Edit Note
        </Button>
        <Button sx={buttonSx(true)} size="small" onClick={handleNoteDelete}>
          Delete Note
        </Button>
      </CardActions>
    </Card>
  );
};
