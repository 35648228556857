import React, { FC, useState, useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios, { AxiosResponse } from 'axios';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { NewNoteModal } from '../components/molecules/modals/NewNoteModal';
import { EditNoteModal } from '../components/molecules/modals/EditNoteModal';
import { DeleteNoteModal } from '../components/molecules/modals/DeleteNoteModal';
import { EditAccountModal } from '../components/molecules/modals/EditAccountModal';

import { Note } from '../components/molecules/Note';
import { Loader } from '../components/atoms/Loader';

import { useModal } from '../hooks/UseModal';
import { deleteRed, orangeMain } from '../constants/colors';

import { userBusinessMapSelector, userIdSelector } from '../store/account';
import { leadSnareAdminSelector } from '../store/site';

import { turnSearchStringIntoObject } from '../helpers/urlHelper';
import { summonFlashMessage } from '../helpers/flashMessage';

import { noteType, userClientType, userType, withTimeStamps } from '../types';
import { apiGeneralResponse } from '../types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;

  min-width: 300px;
  max-width: 600px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding: 15px;
`;

const HeaderSectionWithButton = styled.div<{ transition?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;

  ${({ transition }) =>
    !transition &&
    `
    align-items: center;
  `}

  ${({ transition }) =>
    transition &&
    `
    flex-direction: column;

    button {
      margin: 10px 0 0 0;
    }
  `}

  h2 {
    margin: 0;
  }
`;

const NameHeader = styled.h1`
  margin: 0;
`;

const SubHeader = styled.h2`
  margin-top: 0;
  font-size: 22px;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

const ContentSpan = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
`;

const SectionBody = styled.div<{
  transition?: boolean;
  controlledWidth?: boolean;
  center?: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ transition }) => transition && 'width: 100%;'}
  ${({ controlledWidth }) =>
    controlledWidth && `@media (max-width: 500px) { width: 100%; };`}
  ${({ controlledWidth, center }) =>
    controlledWidth &&
    center &&
    `@media (max-width: 500px) { align-items: center; };`}
`;

interface UserManagementPageProps {}

const cardSx = (withCursor?: boolean) => ({
  '&.MuiPaper-root': {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    transition: 'all 0.25s ease-in-out',
    cursor: withCursor ? 'pointer' : 'default',

    ':hover': {
      transform: 'scale(1.02, 1.01)',
    },
  },
});

const buttonSx = (isDelete?: boolean) => ({
  color: 'black',
  backgroundColor: isDelete ? deleteRed : orangeMain,
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'italic',
  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
    boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
    backgroundColor: isDelete ? deleteRed : orangeMain,
  },

  '@media (max-width: 500px)': {
    margin: isDelete ? '1rem 0 0 0' : '0 0 1rem 0',
  },
});

type applicableUserType = userType & {
  role?: userClientType['role'];
  notes?: Array<withTimeStamps<noteType>>;
};

export const UserManagementPage: FC<UserManagementPageProps> = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const [isUserInformationLoading, setIsUserInformationLoading] =
    useState(false);
  // To be deleted/edited options
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<
    (noteType & { owner: string }) | undefined
  >(undefined);
  const [noteToBeEdited, setNoteToBeEdited] = useState<
    (noteType & { owner: string }) | undefined
  >(undefined);
  const userBusinessesMap = useSelector(userBusinessMapSelector);
  const userIdFromRedux = useSelector(userIdSelector);
  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);

  const [openEditUserModal, OpenUserModalShell, forceCloseEditUserModal] =
    useModal();
  const [openNewNoteModal, NewNoteModalShell, forceCloseNewNoteModal] =
    useModal();
  const [openEditNoteModal, EditNoteModalShell, forceCloseEditNoteModal] =
    useModal();
  const [openDeleteNoteModal, DeleteNoteModalShell, forceCloseDeleteNoteModal] =
    useModal();

  const userInformation = useRef<Partial<applicableUserType>>({});
  const adminClientNotes = useRef<Partial<Array<noteType>>>([]);

  const { width, ref } = useResizeDetector();
  const shouldTransition = width && width >= 500;

  const fetchUserById = useCallback(async () => {
    setIsUserInformationLoading(true);
    try {
      const queryStrings = turnSearchStringIntoObject(window.location.search);
      const clientId = queryStrings.clientId;

      const getURL = clientId
        ? `/users/${userId}?clientId=${clientId}&withNotes=true`
        : `/users/${userId}`; // To get notes we also need clientId

      const userResponse = (await axios.get(getURL)) as AxiosResponse<
        apiGeneralResponse<applicableUserType>
      >;

      if (userResponse.data.success && userResponse.data.result) {
        userInformation.current = userResponse.data
          .result as applicableUserType;
      }
    } catch (error: any) {
      if (error.response?.statusText === 'Forbidden') {
        summonFlashMessage('Unauthorized', 'error');
        navigate(-1);
      } else {
        summonFlashMessage('Error Fetching User', 'error');
        console.error(error);
      }
    } finally {
      setIsUserInformationLoading(false);
    }
  }, [userId, navigate]);

  useEffect(() => {
    if (
      !Object.keys(userInformation).length ||
      userInformation.current.id !== userId
    ) {
      (async () => {
        await fetchUserById();
      })();
    }
  }, [fetchUserById, userId]);

  const handleNoteDelete = async (note: noteType) => {
    const owner = `${userId}`;

    const noteToBeDeleted = {
      ...note,
      owner,
    };

    setNoteToBeDeleted(noteToBeDeleted);
    openDeleteNoteModal();
  };

  const handleNoteEdit = async (note: noteType) => {
    const owner = `${userId}`;

    const noteToBeEdited = {
      ...note,
      owner,
    };

    setNoteToBeEdited(noteToBeEdited);
    openEditNoteModal();
  };

  const queryStrings = turnSearchStringIntoObject(window.location.search);
  const userHasValidCredsWithClient = queryStrings.clientId
    ? ['manager', 'admin'].includes(
        userBusinessesMap[+queryStrings.clientId].role
      )
    : false;

  const hasValidEditAccess =
    isLeadSnareAdmin ||
    userHasValidCredsWithClient ||
    (userId && userIdFromRedux === +userId);

  return (
    <Container ref={ref}>
      <OpenUserModalShell
        contents={
          <EditAccountModal
            user={userInformation.current as userType}
            clientId={
              turnSearchStringIntoObject(window.location.search).clientId
            }
            onSuccess={() => window.location.reload()}
            closeModal={forceCloseEditUserModal}
          />
        }
      />
      <EditNoteModalShell
        contents={
          <EditNoteModal
            adminOnly={noteToBeEdited?.adminOnly as boolean}
            note={noteToBeEdited?.note as string}
            noteId={noteToBeEdited?.id as number}
            target={noteToBeEdited?.owner as string}
            closeModal={forceCloseEditNoteModal}
            onSuccess={fetchUserById}
          />
        }
      />
      <NewNoteModalShell
        contents={
          <NewNoteModal
            closeModal={forceCloseNewNoteModal}
            onSuccess={fetchUserById}
            clientId={
              +turnSearchStringIntoObject(window.location.search).clientId
            }
            userId={userId}
          />
        }
      />
      <DeleteNoteModalShell
        contents={
          <DeleteNoteModal
            note={noteToBeDeleted?.note as string}
            noteId={noteToBeDeleted?.id as number}
            target={noteToBeDeleted?.owner as string}
            closeModal={forceCloseDeleteNoteModal}
            userId={userId}
            onSuccess={fetchUserById}
          />
        }
      />
      {isUserInformationLoading ? (
        <Loader selfAlign isLoading />
      ) : (
        <Container>
          <Body>
            <NameHeader>
              {userInformation.current.firstName}{' '}
              {userInformation.current.lastName}
            </NameHeader>
            <IDSpan>id: {userInformation.current.id}</IDSpan>
            <Paper sx={cardSx()}>
              <ContentSpan>{userInformation.current.email}</ContentSpan>
              <ContentSpan>{userInformation.current.phone}</ContentSpan>
              {userInformation.current.role && (
                <ContentSpan>
                  <strong>Role: </strong>
                  {userInformation.current.role}
                </ContentSpan>
              )}
              {hasValidEditAccess && (
                <Button
                  onClick={openEditUserModal}
                  sx={{
                    color: 'black',
                    backgroundColor: orangeMain,
                    fontWeight: 700,
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'italic',
                    transition: 'all 0.25s ease-in-out',
                    marginTop: '1rem',

                    '&:hover': {
                      transform: 'scale(1.02, 1.01)',
                      boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                      backgroundColor: orangeMain,
                    },
                  }}
                >
                  Edit
                </Button>
              )}
            </Paper>
          </Body>
          {isLeadSnareAdmin && (
            <Body>
              <HeaderSectionWithButton transition={!shouldTransition}>
                <SubHeader>Notes</SubHeader>
                <Button
                  variant="contained"
                  size="medium"
                  sx={buttonSx()}
                  onClick={openNewNoteModal}
                >
                  Add Note
                </Button>
              </HeaderSectionWithButton>
              <SectionBody controlledWidth center>
                {userInformation?.current?.notes?.length ? (
                  userInformation?.current?.notes
                    .sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
                    .map((note, i) => (
                      <Note
                        key={`${note.id}`}
                        note={note}
                        onNoteDelete={() => handleNoteDelete(note)}
                        onNoteEdit={() => handleNoteEdit(note)}
                      />
                    ))
                ) : (
                  <ContentSpan>No Notes Found</ContentSpan>
                )}
              </SectionBody>
            </Body>
          )}
        </Container>
      )}
    </Container>
  );
};
