import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import { ageGenerationsSelector, updateLeadById } from '../../../store/leads';
import { useAppDispatch } from '../../../store';
import { ModalHeader } from '../../atoms/ModalHeader';
import { orangeMain } from '../../../constants/colors';
import { getLeadByIdFull } from '../../../types';

const Container = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  gap: 10px;
`;

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

const inputSx = {
  width: '100%',
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: 'Male' | 'Female' | 'Non-Binary';
  age: string | number;
};

interface EditLeadModalProps {
  closeModal: () => void;
  leadInformation: Partial<getLeadByIdFull>;
  onSuccess: () => Promise<void>;
}

export const EditLeadModal: FC<EditLeadModalProps> = ({
  closeModal,
  leadInformation,
  onSuccess,
}) => {
  const leadType = leadInformation?.lifeCycle?.type ?? 'lead';

  const ageGenerations = useSelector(ageGenerationsSelector);
  const dispatch = useAppDispatch();

  // set Select Input Values on the Component using "defaultValue" instead of here to avoid sticky Select Labels
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: leadInformation?.lead?.firstName ?? '',
      lastName: leadInformation?.lead?.lastName ?? '',
      email: leadInformation?.lead?.email ?? '',
      phone: leadInformation?.lead?.phone ?? '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const updatedLead = {
      leadId: `${leadInformation?.lead?.id ?? '1'}`,
      leadData: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        gender: data.gender,
        age: +data.age,
      },
    };

    await dispatch(updateLeadById(updatedLead));

    if (onSuccess) {
      closeModal();
      await onSuccess();
    } else {
      closeModal();
    }
  };

  return (
    <Container>
      <ModalHeader>Edit {leadType}</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.firstName}
          label="First Name*"
          helperText={!!errors.firstName && 'This Field is Required'}
          {...register('firstName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.lastName}
          label='Last Name or "N/A*'
          helperText={!!errors.lastName && 'This Field is Required'}
          {...register('lastName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.email}
          label="Email*"
          helperText={!!errors.email && 'This Field is Required'}
          {...register('email', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.phone}
          label={'Phone Number*'}
          helperText={!!errors.phone && 'This Field is Required'}
          {...register('phone', { required: true })}
        />
        <FormControl sx={{ mt: 1, width: '100%' }}>
          <InputLabel id="age-select-label">Age</InputLabel>
          <Select
            {...register('age', { required: false })}
            labelId="age-generation-select-label"
            id="age-generation-select"
            defaultValue={leadInformation?.lead?.age}
            value={getValues('age')}
            label="Age"
            onChange={(e) => {
              setValue('age', e.target.value);
            }}
          >
            {ageGenerations.map((gen) => {
              const range = `${gen.yearStart} - ${gen.yearEnd}`;
              const name = gen.generation;
              return <MenuItem value={gen.id}>{`${name} (${range})`}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 1, width: '100%' }}>
          <InputLabel id="gender-select-label">Gender</InputLabel>
          <Select
            {...register('gender', { required: false })}
            labelId="gender-select-label"
            id="gender-select"
            defaultValue={leadInformation?.lead?.gender}
            value={getValues('gender')}
            label="Gender"
            onChange={(e) => {
              if (['Male', 'Female', 'Non-Binary'].includes(e.target.value)) {
                setValue(
                  'gender',
                  e.target.value as 'Male' | 'Female' | 'Non-Binary'
                );
              }
            }}
          >
            {['Male', 'Female', 'Non-Binary'].map((gender) => {
              return <MenuItem value={gender}>{gender}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            width: '100%',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Update
        </Button>
      </Form>
    </Container>
  );
};
