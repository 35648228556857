import React, { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { useAuthInfo } from '@propelauth/react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import Pagination from '@mui/material/Pagination/Pagination';

import { useAppDispatch } from '../store';
import {
  userBusinessesSelector,
  userInfoSelector,
  fetchUserByPropelAuthId,
} from '../store/account';

import { BusinessInfoCard } from '../components/molecules/BusinessInfoCard';
import { EditAccountModal } from '../components/molecules/modals/EditAccountModal';
import { Loader } from '../components/atoms/Loader';

import { orangeMain } from '../constants/colors';
import { useModal } from '../hooks/UseModal';
import { userType } from '../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const NameHeader = styled.h1`
  margin: 0;
`;

const SubHeader = styled.h2`
  margin-top: 0;
  font-size: 24px;

  @media (max-width: 850px) {
    font-size: 20px;
  }
`;

const Section = styled.div<{ first?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 60%;
  padding: 10px;

  ${({ first }) =>
    first &&
    `
    min-width: 35%;
    @media (max-width: 850px) {
      min-width: 90%;
    }
  `}
`;

const ContentSpan = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 850px) {
    font-size: 14px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

const cardSx = (withCursor?: boolean) => ({
  '&.MuiPaper-root': {
    padding: withCursor ? '0 0 5px 0' : '10px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: withCursor ? 0 : '1rem',
    transition: 'all 0.25s ease-in-out',
    cursor: withCursor ? 'pointer' : 'default',
  },
});

interface AccountPageProps {}

export const AccountPage: FC<AccountPageProps> = () => {
  const [pageIndex, setPageIndex] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const [
    openEditAccountModal,
    EditAccountModalShell,
    forceCloseEditAccountModal,
  ] = useModal();
  const [isUserLoading, setUserLoading] = useState(false);
  const [businessSearchValue, setBusinessSearchValue] = useState<string | null>(
    null
  );

  const userBusinessesInformation = useSelector(userBusinessesSelector);
  const userInformation = useSelector(userInfoSelector);

  const propelAuthUserId = authInfo?.user?.userId;

  useEffect(() => {
    if (propelAuthUserId && !userInformation?.id) {
      setUserLoading(true);
      (async () => {
        await dispatch(fetchUserByPropelAuthId(propelAuthUserId));
        setUserLoading(false);
      })();
    }
  }, [dispatch, userInformation, propelAuthUserId]);

  const handleBusinessClick = (businessId?: number) =>
    businessId && navigate(`../business/${businessId}`);

  // Accordian Settings
  const NUM_SEP = 5;
  const NUM_PAGES = Math.ceil(userBusinessesInformation.length / NUM_SEP);
  const flattenedBusinessesChunked = _.chunk(
    userBusinessesInformation,
    NUM_SEP
  );

  const BusinessesComponent: FC = () => {
    if (businessSearchValue) {
      return userBusinessesInformation
        .filter((businesses) =>
          businessSearchValue?.match(businesses.companyName)
        )
        .map((business) => (
          <Paper
            elevation={0}
            sx={cardSx(true)}
            onClick={() => handleBusinessClick(business.id)}
          >
            <Container style={{ width: '99%' }}>
              <BusinessInfoCard businessInfo={business} />
            </Container>
          </Paper>
        ));
    }

    if (!flattenedBusinessesChunked || !flattenedBusinessesChunked.length) {
      return (
        <Typography sx={{ alignSelf: 'center' }}>
          No Businesses Found
        </Typography>
      );
    }

    return flattenedBusinessesChunked[pageIndex] ? (
      flattenedBusinessesChunked[pageIndex].map((business, i) => (
        <Paper
          key={i}
          elevation={0}
          sx={cardSx(true)}
          onClick={() => handleBusinessClick(business.id)}
        >
          <Container style={{ width: '99%' }}>
            <BusinessInfoCard businessInfo={business} />
          </Container>
        </Paper>
      ))
    ) : (
      <Typography sx={{ alignSelf: 'center' }}>End of Businesses</Typography>
    );
  };

  return (
    <Container>
      <EditAccountModalShell
        contents={
          <EditAccountModal
            user={userInformation as userType}
            noRole
            onSuccess={() => window.location.reload()}
            closeModal={forceCloseEditAccountModal}
          />
        }
      />
      <NameHeader style={{ margin: '2rem 0' }}>Account</NameHeader>
      <ContentContainer>
        <Section first>
          <SubHeader>Personal Information</SubHeader>
          <Paper elevation={2} sx={cardSx()}>
            {isUserLoading ? (
              <Loader isLoading selfAlign />
            ) : (
              <>
                <div style={{ display: 'flex' }}>
                  <ContentSpan style={{ paddingRight: '5px' }}>
                    {userInformation?.firstName},
                  </ContentSpan>
                  <ContentSpan>{userInformation?.lastName}</ContentSpan>
                </div>
                <>
                  <ContentSpan>{userInformation?.email}</ContentSpan>
                </>
                <>
                  <ContentSpan>{userInformation?.phone}</ContentSpan>
                </>
                <>
                  <IDSpan>
                    id: <code>{propelAuthUserId}</code>
                  </IDSpan>
                </>
                <>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={openEditAccountModal}
                    sx={{
                      color: 'black',
                      backgroundColor: orangeMain,
                      fontWeight: 700,
                      fontFamily: 'Montserrat, sans-serif',
                      fontStyle: 'italic',
                      transition: 'all 0.25s ease-in-out',
                      margin: '1rem 0 6px 0',

                      '&:hover': {
                        transform: 'scale(1.02, 1.01)',
                        boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                        backgroundColor: orangeMain,
                      },
                    }}
                  >
                    Edit
                  </Button>
                </>
              </>
            )}
          </Paper>
        </Section>
        <Section>
          <SubHeader>Business Information</SubHeader>
          {!userBusinessesInformation || !userBusinessesInformation.length ? (
            <Loader isLoading selfAlign />
          ) : (
            <>
              {userBusinessesInformation.length > 5 && (
                <Autocomplete
                  disablePortal
                  disabled={
                    !userBusinessesInformation ||
                    !userBusinessesInformation.length
                  }
                  options={userBusinessesInformation.map(
                    (business) => `${business.companyName}`
                  )}
                  sx={{
                    width: '100%',
                    minWidth: 200,
                    margin: '0 0 10px 0',
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Business Search" />
                  )}
                  value={businessSearchValue}
                  onChange={(event: any, newValue: string | null) =>
                    setBusinessSearchValue(newValue)
                  }
                />
              )}
              <BusinessesComponent />
              {userBusinessesInformation.length > 5 && (
                <Pagination
                  count={NUM_PAGES}
                  onChange={(e, page) => setPageIndex(page - 1)}
                  sx={{ alignSelf: 'center', marginTop: '10px' }}
                />
              )}
            </>
          )}
        </Section>
      </ContentContainer>
    </Container>
  );
};
