import React, { FC } from 'react';
import axios from 'axios';

import { ConfirmationModal } from './ConfirmationModal';

import { leadStageType, leadType, withTimeStamps } from '../../../types';
import { apiDBResponse } from '../../../types/api';
import { summonFlashMessage } from '../../../helpers/flashMessage';

interface LifeCycleChangeModalProps {
  lead: withTimeStamps<leadType>;
  oldStage: withTimeStamps<leadStageType>;
  newStage: leadStageType;
  onSuccess: () => Promise<void>;
  closeModal: () => void;
}

export const LifeCycleChangeModal: FC<LifeCycleChangeModalProps> = ({
  lead,
  oldStage,
  newStage,
  onSuccess,
  closeModal,
}) => {
  const handleLifeCycleChange = async () => {
    const payload = {
      lifeCycle: newStage.id,
    };

    try {
      const leadData = await axios.patch<apiDBResponse<leadType>>(
        `/leads/${lead.id}`,
        payload
      );

      if (!leadData.data.success || !leadData.data.result) {
        summonFlashMessage('Error updating lead', 'error');
        closeModal();
        return;
      }

      await onSuccess();
      summonFlashMessage(
        `Lead moved from ${oldStage.type} to ${newStage.type}`,
        'success'
      );
      closeModal();
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error updating lead', 'error');
    } finally {
      closeModal();
    }
  };

  return (
    <ConfirmationModal
      onConfirm={handleLifeCycleChange}
      confirmTitle="Confirm LifeCycle Change"
      confirmText={`Are you sure you want to transition ${lead.firstName} from ${oldStage.type} to ${newStage.type}?`}
    />
  );
};
