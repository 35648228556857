import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
// import styled from "styled-components";
import { SxProps, Theme } from '@mui/material';
import { orangeMain } from '../../constants/colors';

interface TagProps {
  tag: string;
  onDelete: (tag: string) => void;
}

const tagSx: SxProps<Theme> = {
  '&.MuiChip-root': {
    margin: '5px',
    backgroundColor: orangeMain,
  },
  '.MuiChip-deleteIcon': {
    color: 'black',
  },
};

export const Tag: FC<TagProps> = ({ tag, onDelete }) => (
  <Chip sx={tagSx} label={tag} onDelete={() => onDelete(tag)} />
);
