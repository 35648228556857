import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router";

import styled from "styled-components";

const Container = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LandingPage: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/leads");
  });

  // TODO Make this a Stats Page

  return <Container>Landing Page</Container>;
};
