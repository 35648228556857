import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import Pagination from '@mui/material/Pagination/Pagination';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';

import { useAppDispatch } from '../store';
import {
  userBusinessesSelector,
  fetchUserClientsByUserId,
} from '../store/account';

import { BusinessInfoCard } from '../components/molecules/BusinessInfoCard';
import { NewBusinessModal } from '../components/molecules/modals/NewBusinessModal';

import { Loader } from '../components/atoms/Loader';

import { orangeMain } from '../constants/colors';
import { useModal } from '../hooks/UseModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 98%;
  }
`;

const Header = styled.h1`
  margin: 6px 0;
`;

const ContentContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-top: 1rem;
`;

interface BusinessesPageProps {}

export const BusinessesPage: FC<BusinessesPageProps> = () => {
  const dispatch = useAppDispatch();
  const userBusinesses = useSelector(userBusinessesSelector);

  const [pageIndex, setPageIndex] = useState(0);
  const [businessSearchValue, setBusinessSearchValue] = useState<string | null>(
    null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [openNewBusinessModal, NewBusinessModalShell, forceCloseBusinessModal] =
    useModal();

  // Get User Client Info
  useEffect(() => {
    // probably should check userId here too
    if (!userBusinesses.length) {
      (async () => {
        setIsLoading(true);
        await dispatch(fetchUserClientsByUserId());
        setIsLoading(false);
      })();
    }
  }, [dispatch, userBusinesses.length]);

  // Accordian Settings
  const NUM_SEP = 10;
  const NUM_PAGES = Math.ceil(userBusinesses.length / NUM_SEP);
  const flattenedBusinessesChunked = _.chunk(userBusinesses, NUM_SEP);

  const BusinessesComponent: FC = () => {
    if (businessSearchValue) {
      return userBusinesses
        .filter((businesses) =>
          businessSearchValue?.match(businesses.companyName)
        )
        .map((business) => <BusinessInfoCard businessInfo={business} />);
    }

    if (!flattenedBusinessesChunked || !flattenedBusinessesChunked.length) {
      return (
        <Typography sx={{ alignSelf: 'center' }}>
          No Businesses Found
        </Typography>
      );
    }

    return flattenedBusinessesChunked[pageIndex] ? (
      flattenedBusinessesChunked[pageIndex].map((business, i) => (
        <BusinessInfoCard key={i} businessInfo={business} />
      ))
    ) : (
      <Typography sx={{ alignSelf: 'center' }}>End of Businesses</Typography>
    );
  };

  return (
    <Container>
      <NewBusinessModalShell
        contents={
          <NewBusinessModal
            onSuccess={() => window.location.reload()}
            closeModal={forceCloseBusinessModal}
          />
        }
      />
      <HeaderSection>
        <Header>Businesses</Header>
        <Button
          variant="contained"
          size="large"
          onClick={openNewBusinessModal}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.02, 1.01)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },

            '@media (max-width: 600px)': {
              width: '100%',
              margin: '0',
            },
          }}
        >
          Create Business
        </Button>
      </HeaderSection>
      {isLoading ? (
        <Loader selfAlign isLoading={isLoading} />
      ) : (
        <ContentContainer>
          {userBusinesses.length > 10 && (
            <Autocomplete
              disablePortal
              disabled={!userBusinesses || !userBusinesses.length}
              options={userBusinesses.map(
                (business) => `${business.companyName}`
              )}
              sx={{
                width: '100%',
                minWidth: 200,
                margin: '0 0 10px 0',
              }}
              renderInput={(params) => (
                <TextField {...params} label="Business Search" />
              )}
              value={businessSearchValue}
              onChange={(event: any, newValue: string | null) =>
                setBusinessSearchValue(newValue)
              }
            />
          )}
          <BusinessesComponent />
          {userBusinesses.length > 10 && (
            <Pagination
              count={NUM_PAGES}
              onChange={(e, page) => setPageIndex(page - 1)}
              sx={{ alignSelf: 'center', marginTop: '10px' }}
            />
          )}
        </ContentContainer>
      )}
    </Container>
  );
};
