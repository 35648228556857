import React, { FC } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useResizeDetector } from 'react-resize-detector';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import { useAppDispatch } from '../../../store';
import { updateUserById } from '../../../store/account';

import { ModalHeader } from '../../atoms/ModalHeader';

import { orangeMain } from '../../../constants/colors';
import { userClientType, userType } from '../../../types';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import axios from 'axios';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

const inputSx = {
  margin: '10px 0',
};

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: userClientType['role'];
};

interface EditAccountModalProps {
  user: userType & { role?: userClientType['role'] };
  clientId?: string;
  noRole?: boolean;
  closeModal: () => void;
  onSuccess?: () => void;
}

export const EditAccountModal: FC<EditAccountModalProps> = ({
  user,
  clientId,
  noRole = false,
  onSuccess,
  closeModal,
}) => {
  const dispatch = useAppDispatch();
  const { ref } = useResizeDetector();

  const {
    register,
    getValues,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      role: user.role,
    },
  });

  const handleSubmission = async () => {
    const values = getValues();
    const diff: Record<string, string | undefined> = {};

    for (const field in touchedFields) {
      diff[field] = values[field as keyof FormValues];
    }

    const wasRoleTouched = user.role !== values.role;

    if (Object.keys(diff).length === 0 && !wasRoleTouched) {
      closeModal();
      return;
    }

    // Remove Role from diff as it is not a user Field
    const { role, ...rest } = diff;

    try {
      if (Object.keys(rest).length !== 0) {
        await dispatch(
          updateUserById({ userId: user.id, userInformation: rest })
        );
      }

      if (clientId && wasRoleTouched) {
        await axios.patch(`userClients/${clientId}/${user.id}`, {
          role: values.role,
        });
      }

      if (onSuccess) {
        summonFlashMessage('Account Updated', 'success');
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Updating Account', 'error');
    } finally {
      closeModal();
    }
  };

  return (
    <Container ref={ref}>
      <ModalHeader>Edit Account</ModalHeader>
      <IDSpan>ID: {user.id}</IDSpan>
      <BodyContainer>
        <TextField
          sx={inputSx}
          error={!!errors.firstName}
          label={!!errors.firstName ? 'Error' : 'First Name'}
          defaultValue={user.firstName}
          helperText={!!errors.firstName && 'This Field is Required'}
          {...register('firstName', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.lastName}
          label={!!errors.lastName ? 'Error' : 'Last Name'}
          defaultValue={user.lastName}
          helperText={!!errors.lastName && 'This Field is Required'}
          {...register('lastName', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.email}
          label={!!errors.email ? 'Error' : 'Email'}
          defaultValue={user.email}
          helperText={!!errors.email && 'This Field is Required'}
          {...register('email', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.phone}
          label={!!errors.phone ? 'Error' : 'Phone'}
          defaultValue={user.phone}
          helperText={!!errors.phone && 'This Field is Required'}
          {...register('phone', { required: true })}
        />
        {!noRole && (
          <Autocomplete
            disablePortal
            defaultValue={user.role || 'user'}
            onChange={(
              _,
              value: userClientType['role'] | undefined | null | string
            ) => {
              if (!value || !['owner', 'user', 'manager'].includes(value))
                return;
              setValue('role', value as userClientType['role']);
            }}
            options={['user', 'manager', 'owner']}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label="Role" />}
          />
        )}
      </BodyContainer>
      <Button
        variant="contained"
        size="large"
        type="submit"
        onClick={handleSubmission}
        sx={{
          color: 'black',
          backgroundColor: orangeMain,
          fontWeight: 700,
          fontFamily: 'Montserrat, sans-serif',
          fontStyle: 'italic',
          width: '100%',
          margin: '10px 0 0 0',
          transition: 'all 0.25s ease-in-out',

          '&:hover': {
            transform: 'scale(1.04, 1.02)',
            boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
            backgroundColor: orangeMain,
          },
        }}
      >
        Update
      </Button>
    </Container>
  );
};
