import React, { FC, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useResizeDetector } from 'react-resize-detector';

import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import HorizontalLine from '../../components/atoms/HorizontalLine';
import { Loader } from '../../components/atoms/Loader';

import { adminGray, orangeMain } from '../../constants/colors';

import { apiDBResponse, apiGeneralResponse } from '../../types/api';
import { summonFlashMessage } from '../../helpers/flashMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 1rem;
`;

const Header = styled.h1`
  font-style: italic;
  margin: 12px 0;
`;

const SubHeader = styled.h2`
  font-style: italic;
  margin: 12px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding: 1rem;
`;

const inputSx = {
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
};

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

type FormValues = {
  pocFirstName?: string;
  pocLastName?: string;
  pocEmail?: string;
  pocPhone?: string;
  companyName: string;
  websiteURL: string;
  companyPhone: string;
  companyEmail: string;
  companyAddress1?: string;
  companyAddress2?: string;
  companyCity?: string;
  companyState?: string;
  companyZip?: string;
  industry: string;
  businessDescription: string;
};

interface AdminOnboardingPageProps {}

export const AdminOnboardingPage: FC<AdminOnboardingPageProps> = () => {
  const { width, ref } = useResizeDetector();
  const [users, setUsers] = useState<
    { id: number; firstName: string; lastName: string }[] | undefined
  >(undefined);
  const [pointOfContactSearchValue, setPointOfContactSearchValue] = useState<
    string | null
  >(null);
  const [fetchingUsers, setFetchingUsers] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchAllUsers = async () => {
    setFetchingUsers(true);
    try {
      const allUsersResponse = (await axios({
        method: 'POST',
        url: '/admin/users',
      })) as AxiosResponse<
        apiDBResponse<{ id: number; firstName: string; lastName: string }>
      >;
      const users = allUsersResponse.data.result;

      if (!users) {
        summonFlashMessage('No Users Found', 'warning');
        return;
      }

      setUsers(users || []);
    } catch (err) {
      console.log('Error Fetching Users', err);
      summonFlashMessage('Error Fetching Users', 'error');
    } finally {
      setFetchingUsers(false);
    }
  };

  const shouldTransition = width && width >= 450;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      pocFirstName: '',
      pocLastName: '',
      pocEmail: '',
      pocPhone: '',
      companyName: '',
      websiteURL: '',
      companyPhone: '',
      companyEmail: '',
      companyAddress1: '',
      companyAddress2: '',
      companyCity: '',
      companyState: '',
      companyZip: '',
      industry: '',
      businessDescription: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const pointOfContactId = pointOfContactSearchValue?.match(
      /\((\d+)\)/
    )?.[1] as string;

    const userPayload = pointOfContactSearchValue
      ? { userId: pointOfContactId }
      : {
          firstName: data.pocFirstName,
          lastName: data.pocLastName,
          email: data.pocEmail,
          phone: data.pocPhone,
        };

    const companyPayload = {
      companyName: data.companyName,
      websiteURL: data.websiteURL,
      companyPhone: data.companyPhone,
      companyEmail: data.companyEmail,
      industry: data.industry,
      businessDescription: data.businessDescription,
    };

    const addressPayload = {
      address1: data.companyAddress1,
      address2: data.companyAddress2,
      city: data.companyCity,
      state: data.companyState,
      zip: data.companyZip,
    };

    const payload = {
      pointOfContact: userPayload,
      clientInformation: companyPayload,
      address: addressPayload,
    };

    try {
      const response = (await axios({
        method: 'POST',
        url: '/admin/onboard',
        data: payload,
      })) as AxiosResponse<
        apiGeneralResponse<{
          addressId: number;
          clientId: number;
          userId: number;
        }>
      >;

      if (response.data.success) {
        setPointOfContactSearchValue(null);
        reset();
        summonFlashMessage('Onboarding Successful', 'success');
        navigate(`/business/${response.data.result?.clientId}`);
      }

      if (response.data.error) {
        summonFlashMessage('Error Onboarding', 'error');
        console.log(response.data.error);
      }
    } catch (err) {
      summonFlashMessage('Error Onboarding', 'error');
      console.log('Error Onboarding', err);
    }
  };

  return (
    <Container ref={ref}>
      <Header>Onboarding</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SubHeader>Point Of Contact</SubHeader>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.pocFirstName}
          label={
            !!errors.pocFirstName ? 'Error' : 'Point Of Contact First Name'
          }
          helperText={!!errors.pocFirstName && 'This Field is Required'}
          {...register('pocFirstName', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.pocLastName}
          label={!!errors.pocLastName ? 'Error' : 'Point Of Contact Last Name'}
          helperText={!!errors.pocLastName && 'This Field is Required'}
          {...register('pocLastName', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.pocEmail}
          label={!!errors.pocEmail ? 'Error' : 'Point Of Contact Email'}
          helperText={!!errors.pocEmail && 'This Field is Required'}
          {...register('pocEmail', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.pocPhone}
          label={!!errors.pocPhone ? 'Error' : 'Point Of Contact Phone'}
          helperText={!!errors.pocPhone && 'This Field is Required'}
          {...register('pocPhone', { required: true })}
        />
        <span
          style={{ textAlign: 'center', fontStyle: 'italic', margin: '10px 0' }}
        >
          or
        </span>
        {!users?.length ? (
          <Loader isLoading={fetchingUsers} selfAlign>
            <Button
              variant="contained"
              size="large"
              onClick={fetchAllUsers}
              sx={{
                width: '100%',
                maxWidth: 500,
                alignSelf: 'center',
                color: 'black',
                backgroundColor: orangeMain,
                fontWeight: 700,
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'italic',
                transition: 'all 0.25s ease-in-out',

                '&:hover': {
                  transform: 'scale(1.04, 1.02)',
                  boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                  backgroundColor: orangeMain,
                },
              }}
            >
              Click to Fetch Users For Point Of Contact
            </Button>
          </Loader>
        ) : (
          <Autocomplete
            disablePortal
            disabled={!users || !users.length}
            options={
              users
                ? users.map(
                    (user) => `${user.firstName} ${user.lastName} (${user.id})`
                  )
                : []
            }
            sx={{
              width: '100%',
              minWidth: 200,
            }}
            renderInput={(params) => (
              <TextField {...params} label="User Search" />
            )}
            value={pointOfContactSearchValue}
            onChange={(event: any, newValue: string | null) =>
              setPointOfContactSearchValue(newValue)
            }
          />
        )}
        <HorizontalLine width="100%" color={adminGray} />
        <SubHeader>Company Information</SubHeader>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyName}
          label={!!errors.companyName ? 'Error' : 'Company Name'}
          helperText={!!errors.companyName && 'This Field is Required'}
          {...register('companyName', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.websiteURL}
          label={!!errors.websiteURL ? 'Error' : 'Website URL'}
          helperText={!!errors.websiteURL && 'This Field is Required'}
          {...register('websiteURL', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyPhone}
          label={!!errors.companyPhone ? 'Error' : 'Company Phone'}
          helperText={!!errors.companyPhone && 'This Field is Required'}
          {...register('companyPhone', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyEmail}
          label={!!errors.companyEmail ? 'Error' : 'Company Email'}
          helperText={!!errors.companyEmail && 'This Field is Required'}
          {...register('companyEmail', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.websiteURL}
          label={!!errors.websiteURL ? 'Error' : 'Company Website URL'}
          helperText={!!errors.websiteURL && 'This Field is Required'}
          {...register('websiteURL', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.industry}
          label={!!errors.industry ? 'Error' : 'Company Industry'}
          helperText={!!errors.industry && 'This Field is Required'}
          {...register('industry', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.businessDescription}
          multiline
          label={!!errors.businessDescription ? 'Error' : 'Company Description'}
          helperText={!!errors.businessDescription && 'This Field is Required'}
          {...register('businessDescription', { required: true })}
        />

        <HorizontalLine width="100%" color={adminGray} />
        <SubHeader>Company Address</SubHeader>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyAddress1}
          label={!!errors.companyAddress1 ? 'Error' : 'Address 1'}
          helperText={!!errors.companyAddress1 && 'This Field is Required'}
          {...register('companyAddress1', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          label={'Address 2'}
          {...register('companyAddress2')}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyCity}
          label={!!errors.companyCity ? 'Error' : 'City'}
          helperText={!!errors.companyCity && 'This Field is Required'}
          {...register('companyCity', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyState}
          label={!!errors.companyState ? 'Error' : 'State'}
          helperText={!!errors.companyState && 'This Field is Required'}
          {...register('companyState', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.companyZip}
          label={!!errors.companyZip ? 'Error' : 'Zip'}
          helperText={!!errors.companyZip && 'This Field is Required'}
          {...register('companyZip', { required: true })}
        />
        <HorizontalLine width="100%" color={adminGray} />
        <Button
          variant="contained"
          size="medium"
          type="submit"
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};
