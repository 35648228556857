import axios, { AxiosResponse } from 'axios';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';

import { RootState } from './index';
import { summonFlashMessage } from '../helpers/flashMessage';

import {
  ageGenerationType,
  leadStageType,
  leadType,
  newLeadType,
  withTimeStamps,
} from '../types';
import { apiDBResponse, fetchAllLeadsType } from '../types/api';

export interface LeadsState {
  leads: fetchAllLeadsType;
  leadStages: Array<leadStageType>;
  ageGenerations: Array<ageGenerationType>;
}

const generateInitialState = (): LeadsState => ({
  leads: [],
  leadStages: [],
  ageGenerations: [],
});

// ASYNC THUNKS
export const fetchLeads = createAsyncThunk<any, number | undefined>(
  'leads/fetchLeads',
  async (clientId, { getState, rejectWithValue }) => {
    try {
      const leadsData = await axios({
        method: 'post',
        url: clientId ? `/clients/${clientId}/leads` : `/leads/all`,
      });

      if (leadsData.data.success) {
        if (clientId) {
          return [
            {
              leads: leadsData.data.result,
              clientId,
            },
          ];
        }
        return leadsData.data.result;
      }

      console.error('Error Getting Leads:', leadsData.data.error);
      summonFlashMessage('Error Getting Leads', 'error');
      rejectWithValue([]);
    } catch (err) {
      summonFlashMessage('Error Getting Leads', 'error');
      if (err instanceof Error) {
        console.error('Error Getting Leads:', err);
        rejectWithValue([]);
      }
    }
  }
);

export const addLead = createAsyncThunk<any, newLeadType>(
  'leads/addLead',
  async (newLeadData, { getState, rejectWithValue }) => {
    try {
      const { account } = getState() as RootState;
      const userId = account.userId;

      const leadData = await axios({
        method: 'post',
        url: `/leads/new`,
        data: {
          ...newLeadData,
          userId,
        },
      });

      if (leadData.data.success) {
        summonFlashMessage('Successfully Created New Lead', 'success');
        return leadData.data.result;
      }

      summonFlashMessage('Error Creating New Lead', 'error');
      rejectWithValue([]);
    } catch (err) {
      summonFlashMessage('Error Creating New Lead', 'error');
      if (err instanceof Error) {
        console.error('Error Getting Leads:', err);
        rejectWithValue([]);
      }
    }
  }
);

export type updateLeadByIdType = {
  leadId: string;
  leadData: Partial<leadType>;
};

export const updateLeadById = createAsyncThunk<any, updateLeadByIdType>(
  'leads/updateLeadById',
  async (updateLeadData, { rejectWithValue, dispatch }) => {
    try {
      const leadData = await axios({
        method: 'patch',
        url: `/leads/${updateLeadData.leadId}`,
        data: {
          ...updateLeadData.leadData,
        },
      });

      if (leadData.data.success) {
        summonFlashMessage('Successfully Updated Lead', 'success');
        return leadData.data.result;
      }

      // If the update failed, refetch leads to be sure state is up to date
      await dispatch(fetchLeads());

      console.error('Error Updating Lead By Id:', leadData.data.error);
      summonFlashMessage('Error Updating Lead', 'error');
      rejectWithValue({});
    } catch (err) {
      summonFlashMessage('Error Updating Lead', 'error');
      if (err instanceof Error) {
        console.error('Error Updating Lead By Id:', err);
        rejectWithValue({});
      }
    }
  }
);

export const fetchLeadStages = createAsyncThunk<any>(
  'leads/fetchLeadStages',
  async (_, { getState, rejectWithValue }) => {
    try {
      const leadStagesData = await axios({
        method: 'get',
        url: `/leadStages/`,
      });

      if (leadStagesData.data.success) {
        return leadStagesData.data.result;
      }

      console.error('Error Getting LeadStages:', leadStagesData.data.error);
      summonFlashMessage('Error Fetching Lead Stages', 'error');
      rejectWithValue([]);
    } catch (err) {
      summonFlashMessage('Error Fetching Lead Stages', 'error');
      if (err instanceof Error) {
        console.error('Error Getting LeadStages:', err);
        rejectWithValue([]);
      }
    }
  }
);

export const fetchLeadById = createAsyncThunk<any, number>(
  'leads/fetchLeadById',
  async (leadId, { getState, rejectWithValue }) => {
    try {
      const { leads } = getState() as RootState;

      // if (!leads.leads) {
      //   rejectWithValue({});
      //   return;
      // }

      // Flatten for easier searching
      const flattenedLeads = leads.leads?.reduce(
        (acc, curr) => [...acc, ...curr.leads],
        [] as Array<leadType>
      );

      const foundLeadInState = flattenedLeads?.find(
        (lead) => lead.id === leadId
      );

      if (foundLeadInState) {
        return foundLeadInState;
      }
      const fetchedLead = await axios({
        method: 'get',
        url: `/leads/${leadId}`,
      });

      if (
        fetchedLead.data.success &&
        fetchedLead.data?.result &&
        fetchedLead.data.result.length > 0
      ) {
        return fetchedLead.data.result[0];
      }

      console.error('Error Getting Lead By Id:', fetchedLead.data.error);
      summonFlashMessage('Error Fetching Lead By ID', 'error');
      rejectWithValue({});
    } catch (err) {
      summonFlashMessage('Error Fetching Lead By ID', 'error');
      if (err instanceof Error) {
        console.error('Error Getting Lead By Id:', err);
        rejectWithValue({});
      }
    }
  }
);

export const fetchAgeGenerations = createAsyncThunk<any>(
  'leads/fetchAgeGenerations',
  async (_, { getState, rejectWithValue }) => {
    try {
      const ageGenerationsData = (await axios({
        method: 'post',
        url: `/ageGenerations/`,
      })) as AxiosResponse<apiDBResponse<ageGenerationType>>;

      if (ageGenerationsData.data.success) {
        return ageGenerationsData.data.result;
      }

      console.error(
        'Error Getting Age Generations:',
        ageGenerationsData.data.error
      );
      summonFlashMessage('Error Fetching Age Generations', 'error');
      rejectWithValue([]);
    } catch (err) {
      summonFlashMessage('Error Fetching Age Generations', 'error');
      if (err instanceof Error) {
        console.error('Error Getting Age Generations:', err);
        rejectWithValue([]);
      }
    }
  }
);

// REDUCERS
const leadSlice: Slice<LeadsState> = createSlice({
  name: 'leads',
  initialState: generateInitialState(),
  reducers: {
    setLeads: (
      state: LeadsState,
      action: PayloadAction<fetchAllLeadsType>
    ) => ({
      ...state,
      leads: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeads.fulfilled, (state, action) => {
      return {
        ...state,
        leads: action.payload as fetchAllLeadsType,
      };
    });
    builder.addCase(fetchLeads.rejected, (state) => {
      return {
        ...state,
        leads: [],
      };
    });
    builder.addCase(addLead.fulfilled, (state, action) => {
      const addedLead = action.payload as withTimeStamps<leadType>;
      const clientId = addedLead.clientId;

      const updatedLeads = state?.leads?.map((lead) => {
        if (lead.clientId === clientId) {
          return {
            ...lead,
            leads: [...lead.leads, addedLead],
          };
        }
        return lead;
      });

      return {
        ...state,
        leads: updatedLeads,
      };
    });
    builder.addCase(addLead.rejected, (state) => {
      return {
        ...state,
        leads: [...state.leads],
      };
    });
    builder.addCase(updateLeadById.fulfilled, (state, action) => {
      const updatedLead = action.payload[0] as withTimeStamps<leadType>;

      const updatedLeads = state.leads.map((lead) => {
        if (lead.clientId === updatedLead.clientId) {
          return {
            clientId: lead.clientId,
            leads: lead.leads.map((lead) => {
              if (lead.id === updatedLead.id) {
                return updatedLead;
              }
              return lead;
            }),
          };
        }
        return lead;
      });

      return {
        ...state,
        leads: updatedLeads,
      };
    });
    builder.addCase(updateLeadById.rejected, (state) => {
      // Action Potentially Failed, do nothing
      return {
        ...state,
      };
    });
    builder.addCase(fetchLeadStages.fulfilled, (state, action) => {
      return {
        ...state,
        leadStages: action.payload as Array<leadStageType>,
      };
    });
    builder.addCase(fetchLeadStages.rejected, (state) => {
      return {
        ...state,
        leadStages: [],
      };
    });
    builder.addCase(fetchAgeGenerations.fulfilled, (state, action) => {
      return {
        ...state,
        ageGenerations: action.payload as Array<ageGenerationType>,
      };
    });
    builder.addCase(fetchAgeGenerations.rejected, (state) => {
      return {
        ...state,
        ageGenerations: [],
      };
    });
  },
});

// SELECTORS
export const leadsSelector = (state: RootState) => state.leads.leads;
export const leadStagesSelector = (state: RootState) => state.leads.leadStages;
export const ageGenerationsSelector = (state: RootState) =>
  state.leads.ageGenerations;

// EXPORTS
export const { setLeads } = leadSlice.actions;
export const leadsReducer = leadSlice.reducer;
