export const PATHS = {
  landing: '/',
  leads: '/leads',
  account: '/account',
  admin: '/admin',
  adminOnboarding: '/admin/onboarding',
  businessInformation: '/business/:id',
  businesses: '/businesses',
  userManagement: '/user/:id',
  lead: '/lead/:leadId',
  estimates: '/estimates',
} as const;
