import { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { estimateType } from '../../../types';

import dayjs from 'dayjs';

import { estimateDetailsType } from '../../../types';

import axios from 'axios';
import { Controller, SubmitHandler, useFieldArray } from 'react-hook-form';
import { parseUSD } from '../../../helpers/USDToNumber';
import { formatAmountAsUSD } from '../../../helpers/formatAmountAsUSD';

import { useSelector } from 'react-redux';
import { summonFlashMessage } from '../../../helpers/flashMessage';
import { userIdSelector } from '../../../store/account';

import AddIcon from '@mui/icons-material/Add';
import MinusIcon from '@mui/icons-material/Remove';
import Button from '@mui/material/Button/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import HorizontalLine from '../../atoms/HorizontalLine';
import { ModalHeader, ModalSubHeader } from '../../atoms/ModalHeader';

import { adminGray, orangeMain } from '../../../constants/colors';
import { getLeadByIdFull } from '../../../types';

const Container = styled.div``;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
  gap: 10px;
`;

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

const inputSx = {
  width: '100%',
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
};

type FormValues = {
  subject: string;
  notes: string;
  expirationDate: any;
  terms: string;
  details: estimateDetailsType[];
  total: string;
};

interface EditEstimateModalProps {
  estimateInformation: estimateType;
  leadName: string;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

export const EditEstimateModalFull: FC<EditEstimateModalProps> = ({
  estimateInformation,
  leadName,
  closeModal,
  onSuccess,
}) => {
  // set Select Input Values on the Component using "defaultValue" instead of here to avoid sticky Select Labels
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      subject: estimateInformation?.subject ?? '',
      notes: estimateInformation?.notes ?? '',
      expirationDate: estimateInformation?.expirationDate
        ? dayjs(estimateInformation?.expirationDate)
        : '',
      terms: estimateInformation?.terms ?? '',
      details: estimateInformation?.details
        ? estimateInformation.details.map((detail) => ({
            itemDetails: detail.itemDetails,
            amount: formatAmountAsUSD(Number(detail.amount)),
          }))
        : [],
      total: estimateInformation?.total
        ? formatAmountAsUSD(Number(estimateInformation.total))
        : '',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details',
  });

  const userId = useSelector(userIdSelector);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const updatedEstimate = {
      subject: data.subject,
      notes: data.notes,
      expirationDate: data.expirationDate,
      terms: data.terms,
      details: data?.details
        ? data.details.map((detail) => ({
            itemDetails: detail.itemDetails,
            amount: detail.amount ? parseUSD(detail.amount) : 0,
          }))
        : [],
      total: parseUSD(data.total),
    };

    try {
      await axios.patch(`/estimates/${estimateInformation.id}`, {
        userId,
        ...updatedEstimate,
      });

      await onSuccess();
      summonFlashMessage('Estimate Updated', 'success');
    } catch (err) {
      summonFlashMessage('Error Updating Estimate', 'error');
      console.log(err);
    } finally {
      closeModal();
    }
  };

  return (
    <Container>
      <ModalHeader>Edit Estimate for {leadName}</ModalHeader>
      <HorizontalLine width="100%" color={adminGray} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
          Estimate Information
        </ModalSubHeader>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.subject}
          label="Subject*"
          helperText={!!errors.subject && 'This Field is Required'}
          {...register('subject', { required: true })}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          multiline
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.notes}
          label="Notes*"
          helperText={!!errors.notes && 'This Field is Required'}
          {...register('notes', { required: true })}
        />
        <Controller
          control={control}
          rules={{ required: true }}
          name="expirationDate"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <>
              <div style={{ marginTop: '10px' }} />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={value}
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      sx: inputSx,
                      label: 'Expiration Date*',
                      error: !!errors.expirationDate,
                      helperText:
                        !!errors.expirationDate && 'This Field is Required',
                    },
                  }}
                />
              </LocalizationProvider>
            </>
          )}
        />
        <TextField
          margin="dense"
          sx={inputSx}
          multiline
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.terms}
          label="Terms & Conditions*"
          helperText={!!errors.terms && 'This Field is Required'}
          {...register('terms', { required: true })}
        />
        <Table sx={{ width: '100%' }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Item Details</b>
              </TableCell>
              <TableCell>
                <b>Amount</b>
              </TableCell>
              <TableCell align="right">
                <Button
                  size="small"
                  sx={{ padding: '0' }}
                  onClick={() =>
                    append({ itemDetails: undefined, amount: undefined })
                  }
                >
                  <AddIcon />
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <TextField
                    sx={inputSx}
                    error={!!errors.details?.[index]?.itemDetails}
                    helperText={
                      !!errors.details?.[index]?.itemDetails &&
                      'This Field is Required'
                    }
                    {...register(`details.${index}.itemDetails`, {
                      required: true,
                    })}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    sx={inputSx}
                    error={!!errors.details?.[index]?.amount}
                    helperText={
                      !!errors.details?.[index]?.amount &&
                      'This Field is Required'
                    }
                    {...register(`details.${index}.amount`, {
                      required: true,
                      onBlur: (e) => {
                        setValue(
                          `details.${index}.amount`,
                          formatAmountAsUSD(parseUSD(e.target.value) ?? 0)
                        );
                        if (
                          getValues(`details.${index}.amount`)?.includes('NaN')
                        ) {
                          setValue(`details.${index}.amount`, '$0.00');
                        }
                      },
                      onChange: (e) => {
                        const regex = /^[0-9.]*$/;
                        if (!regex.test(e.target.value)) {
                          // If input doesn't match the allowed pattern, remove non-numeric characters
                          e.target.value = e.target.value.replace(
                            /[^0-9.]/g,
                            ''
                          );
                        }

                        const [details] = getValues(['details']);

                        setValue(
                          'total',
                          formatAmountAsUSD(
                            details.reduce(
                              (acc, curr) =>
                                acc +
                                (curr?.amount ? parseUSD(curr.amount) : 0),
                              0
                            )
                          )
                        );

                        if (getValues('total').includes('NaN')) {
                          setValue('total', '$0.00');
                        }
                      },
                    })}
                  />
                </TableCell>
                <TableCell align="right">
                  <Button
                    size="small"
                    sx={{ padding: '0' }}
                    onClick={() => {
                      if (index === 0) return;
                      remove(index);
                    }}
                  >
                    <MinusIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                <TextField
                  inputProps={{ readOnly: true }}
                  sx={inputSx}
                  {...register('total')}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <HorizontalLine width="100%" color={adminGray} />
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            width: '100%',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};
