import React, { FC } from 'react';
import styled from 'styled-components';

import { InternalTag } from '../atoms/InternalTag';

import { userType } from '../../types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
`;

const Name = styled.h3`
  margin: 0;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin: 5px 0;
`;

interface UserInfoCardProps {
  userInfo: Partial<userType> & { clientId: number; role?: string };
}

export const UserInfoCard: FC<UserInfoCardProps> = ({ userInfo }) => {
  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Name>{`${userInfo.firstName} ${userInfo.lastName}`}</Name>
        {userInfo.role && <InternalTag>{userInfo.role}</InternalTag>}
      </div>
      <IDSpan>
        id: <code>{userInfo.id}</code>
      </IDSpan>
      <span>{userInfo.email}</span>
    </Container>
  );
};
