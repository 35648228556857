import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import styled from 'styled-components';

import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';
import Divider from '@mui/material/Divider/Divider';
import Pagination from '@mui/material/Pagination';

import { Client } from '../../components/molecules/Client';
import { Loader } from '../../components/atoms/Loader';

import { orangeMain } from '../../constants/colors';

import { clientType } from '../../types';
import { apiDBResponse } from '../../types/api';
import { summonFlashMessage } from '../../helpers/flashMessage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 90%;
`;

const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const ClientSearchAddContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 20px 0px 10px 0px;
  align-self: center;
`;

interface AdminPageProps {}

export const AdminPage: FC<AdminPageProps> = () => {
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientSearchValue, setClientSearchValue] = useState<string | null>(
    null
  );
  const [clients, setClients] = useState<clientType[]>([]);

  const navigateToOnboarding = () => navigate('/admin/onboarding');

  const NUM_SEP = 10;
  const NUM_PAGES = Math.ceil(clients.length / NUM_SEP);
  const clientsChunked = _.chunk(clients, NUM_SEP);

  const getClients = async () => {
    setIsLoading(true);
    try {
      const response = (await axios.post('/admin/clients/')) as AxiosResponse<
        apiDBResponse<clientType>
      >;
      if (response.data.success && response.data.result?.length) {
        setClients(response.data.result);
      }
    } catch (error) {
      summonFlashMessage('Error Fetching Clients', 'error');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!clients.length) {
      (async () => {
        await getClients();
      })();
    }
  }, [clients]);

  const ClientsComponent: FC = () => {
    if (clientSearchValue) {
      return clients
        ?.filter((client) => clientSearchValue?.match(`${client.companyName}`))
        .map((client) => <Client client={client} />);
    }

    return clientsChunked[pageIndex]?.map((client, i) => (
      <Client key={i} client={client} />
    ));
  };

  return (
    <Container>
      <HeadContainer>
        <ClientSearchAddContainer>
          <Autocomplete
            disablePortal
            disabled={!clients || !clients.length}
            options={clients.map((client) => `${client.companyName}`)}
            sx={{
              width: '60%',
              maxWidth: 500,
              minWidth: 200,
              marginRight: '20px',
            }}
            renderInput={(params) => (
              <TextField {...params} label="Client Search" />
            )}
            value={clientSearchValue}
            onChange={(event: any, newValue: string | null) =>
              setClientSearchValue(newValue)
            }
          />
          <Button
            variant="contained"
            size="large"
            onClick={navigateToOnboarding}
            sx={{
              color: 'black',
              backgroundColor: orangeMain,
              fontWeight: 700,
              fontFamily: 'Montserrat, sans-serif',
              fontStyle: 'italic',
              transition: 'all 0.25s ease-in-out',

              '&:hover': {
                transform: 'scale(1.04, 1.02)',
                boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                backgroundColor: orangeMain,
              },
            }}
          >
            Add Client
          </Button>
        </ClientSearchAddContainer>
      </HeadContainer>
      <Divider sx={{ borderWidth: '1px', borderColor: orangeMain }} />
      <BodyContainer>
        {isLoading ? (
          <Loader isLoading selfAlign />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ClientsComponent />
            <Pagination
              count={NUM_PAGES}
              onChange={(e, page) => setPageIndex(page - 1)}
              sx={{ alignSelf: 'center', marginTop: '10px' }}
            />
          </div>
        )}
      </BodyContainer>
    </Container>
  );
};
