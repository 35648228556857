import React, { FC } from 'react';
import axios from 'axios';
import { ConfirmationModal } from './ConfirmationModal';
import { summonFlashMessage } from '../../../helpers/flashMessage';

interface DeleteNoteModalProps {
  note: string;
  noteId: number;
  leadId?: string;
  userId?: string;
  clientId?: string;
  target?: string;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

export const DeleteNoteModal: FC<DeleteNoteModalProps> = ({
  note,
  noteId,
  target,
  closeModal,
  onSuccess,
}) => {
  const handleNoteDelete = async () => {
    try {
      await axios.delete(`/notes/${noteId}`);
      summonFlashMessage('Note Deleted', 'success');
      await onSuccess();
    } catch (err) {
      summonFlashMessage('Error Deleting Note', 'error');
      console.log(err);
    } finally {
      closeModal();
    }
  };

  return (
    <ConfirmationModal
      onConfirm={handleNoteDelete}
      confirmTitle={`Are you sure you want to remove this note${
        target && ' from ' + target
      }`}
      confirmText={note}
    />
  );
};
