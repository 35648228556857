import React, { FC } from 'react';
import axios from 'axios';

import { ConfirmationModal } from './ConfirmationModal';

import { leadType } from '../../../types';
import { apiDBResponse } from '../../../types/api';
import { summonFlashMessage } from '../../../helpers/flashMessage';

interface DeleteTagModalProps {
  tag: string;
  tags: Array<string>;
  leadId?: string;
  userId?: string;
  clientId?: string;
  target?: string;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

export const DeleteTagModal: FC<DeleteTagModalProps> = ({
  tag,
  onSuccess,
  closeModal,
  ...props
}) => {
  const handleTagDelete = async () => {
    try {
      if (!props.leadId) return;

      const validTags = props.tags.filter((t) => t !== tag);
      const payload = {
        tags: validTags,
      };

      const leadData = await axios.patch<apiDBResponse<leadType>>(
        `/leads/${props.leadId}`,
        payload
      );

      if (!leadData.data.success || !leadData.data.result) {
        console.log('Error updating lead');
        closeModal();
        return;
      }

      await onSuccess();
      summonFlashMessage('Tag Deleted', 'success');
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Deleting Tag', 'error');
    } finally {
      closeModal();
    }
  };

  return (
    <ConfirmationModal
      onConfirm={handleTagDelete}
      confirmTitle={`Are you sure you want to remove this tag${
        props.target ? ' from ' + props.target : ''
      }?`}
      confirmText={tag}
    />
  );
};
