import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useAppDispatch } from '../../store';
import { userBusinessesSelector } from '../../store/account';
import { leadSnareAdminSelector, setSideBar } from '../../store/site';

import { Button } from '../atoms/Button';

import { orangeMain } from '../../constants/colors';
import { PATHS } from '../../constants/paths';

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 1rem 1rem 0 1rem;
  flex-direction: column;
  background-color: ${orangeMain};
  justify-content: space-between;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavigationItem = styled(Button)<{ isCurrent: boolean }>`
  color: black;
  margin: 15px 15px 0 15px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0;
  ${({ isCurrent }) =>
    isCurrent &&
    `
      border-bottom: 5px solid #000;
      padding-bottom: 10px;
    `}
`;

export const LeftDrawerMenu: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);
  const userClients = useSelector(userBusinessesSelector);

  const handleNavClick = (path: string) => {
    dispatch(setSideBar(false));
    navigate(path);
  };

  const handleAccountClick = () => navigate(PATHS.account);

  const moreThanOneBusiness = userClients.length > 1;

  const navList: Array<{ name: string; path: string }> = [
    // { name: 'Home', path: PATHS.landing },
    { name: 'Leads', path: PATHS.leads },
    {
      name: moreThanOneBusiness ? 'My Companies' : 'My Company',
      path: PATHS.businesses,
    },
    { name: 'Estimates', path: PATHS.estimates },
  ];

  if (isLeadSnareAdmin) {
    navList.push({ name: 'Admin', path: PATHS.admin });
  }

  const currentPath = window.location.pathname;

  return (
    <Container>
      <Section>
        {navList.map((navItem) => (
          <NavigationItem
            key={navItem.name}
            isCurrent={navItem.path === currentPath}
            onClick={() => handleNavClick(navItem.path)}
          >
            {navItem.name}
          </NavigationItem>
        ))}
      </Section>
      <Section style={{ alignItems: 'flex-start', marginBottom: '1rem' }}>
        <IconButton onClick={handleAccountClick}>
          <AccountCircleIcon fontSize="large" sx={{ color: 'black' }} />
        </IconButton>
      </Section>
    </Container>
  );
};
