import React, { FC } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useResizeDetector } from 'react-resize-detector';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import { ModalHeader } from '../../atoms/ModalHeader';
import { orangeMain } from '../../../constants/colors';

import { addressType, leadType, withTimeStamps } from '../../../types';
import { apiDBResponse } from '../../../types/api';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

const inputSx = {
  margin: '10px 0',
};

type FormValues = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

interface EditAddressModalProps {
  addressInformation: withTimeStamps<addressType>;
  leadId?: number;
  type?: 'service' | 'business';
  closeModal: () => void;
  onSuccess?: () => void;
}

export const EditAddressModal: FC<EditAddressModalProps> = ({
  addressInformation,
  leadId,
  type,
  closeModal,
  onSuccess,
}) => {
  const { ref } = useResizeDetector();
  const {
    register,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormValues>({
    defaultValues: {
      address1: addressInformation?.address1,
      address2: addressInformation?.address2,
      city: addressInformation?.city,
      state: addressInformation?.state,
      zip: addressInformation?.zip,
    },
  });

  const handleSubmission = async () => {
    const values = getValues();
    const diff: Record<string, string | undefined> = {};

    for (const field in touchedFields) {
      if (field === undefined) {
        continue;
      }
      diff[field] = values[field as keyof FormValues];
    }

    if (Object.keys(diff).length === 0) {
      closeModal();
      return;
    }

    const payload = {
      address1: diff.address1,
      address2: diff.address2,
      city: diff.city,
      state: diff.state,
      zip: diff.zip,
    };

    try {
      let response;

      if (!addressInformation?.id && leadId) {
        const fullPayload = {
          ...payload,
          leadId,
          type,
        };

        response = (await axios.post(
          `/leads/${leadId}/address`,
          fullPayload
        )) as AxiosResponse<apiDBResponse<withTimeStamps<leadType>>>;
      } else {
        response = (await axios.patch(
          `/address/${addressInformation.id}`,
          payload
        )) as AxiosResponse<apiDBResponse<withTimeStamps<addressType>>>;
      }

      if (!response?.data.success || !response?.data.result) {
        summonFlashMessage('Error Updating Address', 'error');
        closeModal();
        return;
      }

      if (onSuccess) {
        summonFlashMessage('Address Updated', 'success');
        onSuccess();
      }
    } catch (err) {
      summonFlashMessage('Error Updating Address', 'error');
      console.log(err);
    } finally {
      closeModal();
    }
  };

  return (
    <Container ref={ref}>
      <ModalHeader>Edit Address</ModalHeader>
      {addressInformation?.id && <IDSpan>ID: {addressInformation?.id}</IDSpan>}
      <BodyContainer>
        <TextField
          sx={inputSx}
          error={!!errors.address1}
          label={!!errors.address1 ? 'Error' : 'Address 1'}
          defaultValue={addressInformation?.address1 || ''}
          helperText={!!errors.address1 && 'This Field is Required'}
          {...register('address1', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.address2}
          label={!!errors.address2 ? 'Error' : 'Address 2'}
          defaultValue={addressInformation?.address2 || ''}
          helperText={!!errors.address2 && 'This Field is Required'}
          {...register('address2', { required: false })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.city}
          label={!!errors.city ? 'Error' : 'City'}
          defaultValue={addressInformation?.city || ''}
          helperText={!!errors.city && 'This Field is Required'}
          {...register('city', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.state}
          label={!!errors.state ? 'Error' : 'State'}
          defaultValue={addressInformation?.state || ''}
          helperText={!!errors.state && 'This Field is Required'}
          {...register('state', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.zip}
          label={!!errors.zip ? 'Error' : 'Zip'}
          defaultValue={addressInformation?.zip || ''}
          helperText={!!errors.zip && 'This Field is Required'}
          {...register('zip', { required: true })}
        />
      </BodyContainer>
      <Button
        variant="contained"
        size="large"
        type="submit"
        onClick={handleSubmission}
        sx={{
          color: 'black',
          backgroundColor: orangeMain,
          fontWeight: 700,
          fontFamily: 'Montserrat, sans-serif',
          fontStyle: 'italic',
          width: '100%',
          margin: '10px 0 0 0',
          transition: 'all 0.25s ease-in-out',

          '&:hover': {
            transform: 'scale(1.04, 1.02)',
            boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
            backgroundColor: orangeMain,
          },
        }}
      >
        Update
      </Button>
    </Container>
  );
};
