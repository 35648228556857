import React from 'react';
import ReactDOM from 'react-dom/client';
import { RequiredAuthProvider, RedirectToLogin } from '@propelauth/react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import { store } from './store/index';
import LoadingPage from './pages/LoadingPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const isLocal = window.location.hostname === 'localhost';

Sentry.init({
  dsn: isLocal ? '' : process.env.REACT_APP_SENTRY_DSN,
  environment: isLocal ? 'development' : 'production',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100%
  // while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.5,
  // If you're not already sampling the entire session,
  // change the sample rate to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

root.render(
  <React.StrictMode>
    <RequiredAuthProvider
      authUrl={process.env.REACT_APP_AUTH_URL as string}
      displayWhileLoading={<LoadingPage />}
      displayIfLoggedOut={<RedirectToLogin />}
    >
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={5000}
          TransitionProps={{ direction: 'up' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </Provider>
    </RequiredAuthProvider>
  </React.StrictMode>
);
