import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import Paper from '@mui/material/Paper';

import { orangeMain } from '../../constants/colors';
import { clientType, clientTypeFull } from '../../types';

const cardSx = {
  '&.MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    'justify-content': 'space-between',
    cursor: 'pointer',
    padding: '10px',
    margin: '0 0 10px 0',
    width: '98%',
    border: `2px solid ${orangeMain}`,
    transition: 'all 0.25s ease-in-out',
    'box-shadow': '2px 2px 5px 2px rgba(189, 195, 199, 1)',
    '&:hover': {
      transform: 'scale(1.04, 1.02)',
      'box-shadow': '3px 3px 5px 4px rgba(189, 195, 199, 1)',
    },

    '@media (max-width: 850px)': {
      // padding: '0 0 10px 0',
    },
  },
};

const BusinessName = styled.h3`
  margin: 0;
  margin-bottom: 5px;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
`;

const BusinessURL = styled.span`
  font-size: 14px;
  margin: 10px 0 0 0;
  font-weight: 600;
`;

interface BusinessInfoCardProps {
  businessInfo: Partial<clientType> | Partial<clientTypeFull>;
}

export const BusinessInfoCard: FC<BusinessInfoCardProps> = ({
  businessInfo,
}) => {
  const { websiteURL, companyName, id } = businessInfo;

  const navigate = useNavigate();

  const handleBusinessClick = () => navigate(`/business/${id}`);

  return (
    <Paper sx={cardSx} onClick={handleBusinessClick}>
      <BusinessName>{companyName}</BusinessName>
      <IDSpan>
        id: <code>{id}</code>
      </IDSpan>
      <BusinessURL>{websiteURL}</BusinessURL>
    </Paper>
  );
};
