import React, { FC } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useResizeDetector } from 'react-resize-detector';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import { ModalHeader } from '../../atoms/ModalHeader';
import HorizontalLine from '../../atoms/HorizontalLine';

import { adminGray, orangeMain } from '../../../constants/colors';
import {
  addressType,
  clientTypeFull,
  userType,
  withTimeStamps,
} from '../../../types';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import axios, { AxiosResponse } from 'axios';
import { apiDBResponse } from '../../../types/api';
import { summonFlashMessage } from '../../../helpers/flashMessage';

const Container = styled.div``;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;

const IDSpan = styled.span`
  font-style: italic;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
`;

const inputSx = {
  margin: '10px 0',
};

type FormValues = {
  companyName: string;
  companyWebsite: string;
  companyNumber: string;
  companyIndustry: string;
  companyDescription: string;
  companyPointOfContact: userType;
  companyAddress: addressType;
};

interface EditCompanyInformationModalProps {
  company: clientTypeFull;
  users: userType[];
  closeModal: () => void;
  onSuccess?: () => void;
}

export const EditCompanyInformationModal: FC<
  EditCompanyInformationModalProps
> = ({ company, users, closeModal, onSuccess }) => {
  const { ref } = useResizeDetector();

  const { pointOfContact, companyAddress } = company;

  const {
    register,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormValues>({
    defaultValues: {
      companyName: company.companyName,
      companyWebsite: company.websiteURL,
      companyNumber: company.companyPhone,
      companyIndustry: company.industry,
      companyDescription: company.businessDescription,
      companyPointOfContact: pointOfContact,
      companyAddress,
    },
  });

  const originalPointOfContact = `${pointOfContact.firstName} ${pointOfContact.lastName} (${pointOfContact.id})`;
  const [userSearchValue, setUserSearchValue] = React.useState<string | null>(
    originalPointOfContact
  );

  const handleSubmission = async () => {
    const values = getValues();
    const diff: Record<string, any> = {};

    const changeInPOC =
      originalPointOfContact.trim() !== userSearchValue?.trim();

    for (const field in touchedFields) {
      diff[field] = values[field as keyof FormValues];
    }

    if (Object.keys(diff).length === 0 && !changeInPOC) {
      closeModal();
      return;
    }

    const payload = {
      companyName: diff.companyName,
      websiteURL: diff.companyWebsite,
      companyPhone: diff.companyNumber,
      industry: diff.companyIndustry,
      businessDescription: diff.companyDescription,
      companyAddress: companyAddress.id,
      pointOfContact: pointOfContact.id,
    };

    try {
      // Handle Address Update
      if (diff.companyAddress) {
        const addressId = diff.companyAddress.id;
        delete diff.companyAddress.id;

        const addressResponse = (await axios.patch(
          `/address/${addressId}`,
          diff.companyAddress
        )) as AxiosResponse<apiDBResponse<withTimeStamps<addressType>>>;

        if (!addressResponse.data.success || !addressResponse.data.result) {
          // Flash Error, Failed to update Address
          summonFlashMessage('Error Updating Address', 'error');
        }
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Updating Address', 'error');
      // Flash Error, Failed to update Address
    }

    // Update Point of Contact
    if (changeInPOC) {
      // Parse User ID From Search
      const userId = userSearchValue?.match(/\((\d+)\)/)?.[1] as string;

      payload.pointOfContact = +userId;
    }

    try {
      const response = (await axios.patch(
        `/clients/${company.id}`,
        payload
      )) as AxiosResponse<apiDBResponse<withTimeStamps<clientTypeFull>>>;

      if (!response.data.success || !response.data.result) {
        // Flash Error, Failed to update Client
        summonFlashMessage('Error Updating Client', 'error');
        closeModal();
        return;
      }

      if (onSuccess) {
        summonFlashMessage('Client Updated', 'success');
        onSuccess();
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Updating Client', 'error');
    } finally {
      closeModal();
    }
  };

  return (
    <Container ref={ref}>
      <ModalHeader>Edit Company Information</ModalHeader>
      <IDSpan>ID: {company.id}</IDSpan>
      <HorizontalLine width="100%" color={adminGray} />
      <BodyContainer>
        <TextField
          sx={inputSx}
          error={!!errors.companyName}
          label={!!errors.companyName ? 'Error' : 'Company Name'}
          defaultValue={company.companyName}
          helperText={!!errors.companyName && 'This Field is Required'}
          {...register('companyName', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyWebsite}
          label={!!errors.companyWebsite ? 'Error' : 'Company Website'}
          defaultValue={company.websiteURL}
          helperText={!!errors.companyWebsite && 'This Field is Required'}
          {...register('companyWebsite', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyNumber}
          label={!!errors.companyNumber ? 'Error' : 'Company Number'}
          defaultValue={company.companyPhone}
          helperText={!!errors.companyNumber && 'This Field is Required'}
          {...register('companyNumber', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyIndustry}
          label={!!errors.companyIndustry ? 'Error' : 'Company Industry'}
          defaultValue={company.industry}
          helperText={!!errors.companyIndustry && 'This Field is Required'}
          {...register('companyIndustry', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyDescription}
          label={!!errors.companyDescription ? 'Error' : 'Company Description'}
          defaultValue={company.businessDescription}
          helperText={!!errors.companyDescription && 'This Field is Required'}
          {...register('companyDescription', { required: true })}
        />
        <Autocomplete
          disablePortal
          disabled={!users || !users.length}
          options={users.map(
            (user) => `${user.firstName} ${user.lastName} (${user.id})`
          )}
          sx={{
            width: '100%',
            margin: '10px 0',
          }}
          renderInput={(params) => (
            <TextField {...params} label="Company Point of Contact" />
          )}
          value={userSearchValue}
          onChange={(event: any, newValue: string | null) =>
            setUserSearchValue(newValue)
          }
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyAddress?.address1}
          label={!!errors.companyAddress?.address1 ? 'Error' : 'Address 1'}
          defaultValue={companyAddress.address1}
          helperText={
            !!errors.companyAddress?.address1 && 'This Field is Required'
          }
          {...register('companyAddress.address1', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyAddress?.address2}
          label={!!errors.companyAddress?.address2 ? 'Error' : 'Address 2'}
          defaultValue={companyAddress.address2}
          helperText={
            !!errors.companyAddress?.address2 && 'This Field is Required'
          }
          {...register('companyAddress.address2', { required: false })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyAddress?.city}
          label={!!errors.companyAddress?.city ? 'Error' : 'City'}
          defaultValue={companyAddress.city}
          helperText={!!errors.companyAddress?.city && 'This Field is Required'}
          {...register('companyAddress.city', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyAddress?.state}
          label={!!errors.companyAddress?.state ? 'Error' : 'State'}
          defaultValue={companyAddress.state}
          helperText={
            !!errors.companyAddress?.state && 'This Field is Required'
          }
          {...register('companyAddress.state', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyAddress?.zip}
          label={!!errors.companyAddress?.zip ? 'Error' : 'Zip'}
          defaultValue={companyAddress.zip}
          helperText={!!errors.companyAddress?.zip && 'This Field is Required'}
          {...register('companyAddress.zip', { required: true })}
        />
      </BodyContainer>
      <Button
        variant="contained"
        size="large"
        type="submit"
        onClick={handleSubmission}
        sx={{
          color: 'black',
          backgroundColor: orangeMain,
          fontWeight: 700,
          fontFamily: 'Montserrat, sans-serif',
          fontStyle: 'italic',
          width: '100%',
          margin: '10px 0 0 0',
          transition: 'all 0.25s ease-in-out',

          '&:hover': {
            transform: 'scale(1.04, 1.02)',
            boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
            backgroundColor: orangeMain,
          },
        }}
      >
        Update
      </Button>
    </Container>
  );
};
