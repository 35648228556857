import axios from 'axios';
import { FC } from 'react';
import { summonFlashMessage } from '../../../helpers/flashMessage';
import { estimateType } from '../../../types';
import { ConfirmationModal } from './ConfirmationModal';

interface DeleteEstimateModalProps {
  estimate: Partial<estimateType>;
  target?: string;
  closeModal: () => void;
  onSuccess: () => Promise<void>;
}

export const DeleteEstimateModal: FC<DeleteEstimateModalProps> = ({
  estimate,
  target,
  closeModal,
  onSuccess,
}) => {
  const handleEstimateDelete = async () => {
    try {
      await axios.delete(`/estimates/${estimate.id}`);
      summonFlashMessage('Estimate Deleted', 'success');
      await onSuccess();
    } catch (err) {
      summonFlashMessage('Error Deleting Estimate', 'error');
      console.log(err);
    } finally {
      closeModal();
    }
  };

  return (
    <ConfirmationModal
      onConfirm={handleEstimateDelete}
      confirmTitle={`Are you sure you want to remove this estimate${
        target && ' from ' + target
      }`}
      confirmText={estimate?.subject ? estimate.subject : ''}
    />
  );
};
