import React, { FC, useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import _ from 'lodash';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import AddIcon from '@mui/icons-material/Add';

import { useAppDispatch } from '../store';
import { userBusinessesSelector } from '../store/account';
import { leadSnareAdminSelector } from '../store/site';
import { fetchLeads, leadsSelector, leadStagesSelector } from '../store/leads';

import { Lead } from '../components/molecules/Lead';
import { AddLeadModal } from '../components/molecules/modals/AddLeadModal';
import CompanyChipSelect from '../components/molecules/MultipleChipSelect';

import { Loader } from '../components/atoms/Loader';
import HorizontalLine from '../components/atoms/HorizontalLine';

import { useModal } from '../hooks/UseModal';
import {
  turnSearchStringIntoObject,
  clearSearchParams,
} from '../helpers/urlHelper';
import { summonFlashMessage } from '../helpers/flashMessage';
import { mobileCheck } from '../helpers/mobileCheck';

import { adminGray, orangeMain } from '../constants/colors';
import {
  clientType,
  filesFilterRequestBody,
  leadType,
  withTimeStamps,
} from '../types';
import { apiDBResponse, apiGeneralResponse } from '../types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 90%;
`;

const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const LeadSearchAddContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 20px 0px 10px 0px;
  align-self: center;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const buttonSx = {
  color: 'black',
  backgroundColor: orangeMain,
  fontWeight: 700,
  fontFamily: 'Montserrat, sans-serif',
  fontStyle: 'italic',
  transition: 'all 0.25s ease-in-out',

  '&:hover': {
    transform: 'scale(1.04, 1.02)',
    boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
    backgroundColor: orangeMain,
  },
};

interface LeadsPageProps {}

export const LeadsPage: FC<LeadsPageProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ref, width } = useResizeDetector();

  const isLeadSnareAdmin = useSelector(leadSnareAdminSelector);
  const leads = useSelector(leadsSelector);
  const userBusinesses = useSelector(userBusinessesSelector);
  const leadStageInformation = useSelector(leadStagesSelector);

  // Pagination
  const [NUM_PAGES, setNumberOfPages] = useState<number>(0);

  const [pageIndex, setPageIndex] = useState(0);
  const [leadsLoading, setLeadsLoading] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState<boolean>(false);
  const [leadSearchValue, setLeadSearchValue] = useState<string | null>(null);
  const [queryParamsJson, setQueryParamsJson] = useState({} as any);
  const [clientName, setClientName] = useState<string | undefined>(undefined);

  // FILTERS
  const [filteredLeads, setFilteredLeads] = useState<
    Array<withTimeStamps<leadType>>
  >([]);
  const [appliedFilters, setAppliedFilters] = useState<boolean>(false);
  const [selectedFilterBusiness, setSelectedFilterBusiness] = useState<
    Array<string | number>
  >([]);
  const [selectedLeadStages, setSelectedLeadStages] = useState<Array<number>>(
    []
  );
  const orderByOptions = ['Created', 'Updated', 'Alphabetical'] as const;
  const orderDirectionOptions = ['ASCENDING', 'DESCENDING'] as const;
  const [orderBy, setOrderBy] = useState<(typeof orderByOptions)[number]>(
    orderByOptions[0]
  );
  const [orderDirection, setOrderDirection] = useState<
    (typeof orderDirectionOptions)[number]
  >(orderDirectionOptions[0]);

  // MODALS
  const [openAddLeadModal, AddLeadModalShell, forceCloseAddLeadModal] =
    useModal();

  const isMobile = mobileCheck();
  const isSmallScreen = width && width < 500;

  const shouldTransitionFilters = width && width < 700;

  const usePlus = isMobile || isSmallScreen;

  const flattenedLeads = leads?.reduce(
    (acc, curr) => [...acc, ...curr.leads],
    [] as Array<withTimeStamps<leadType>>
  );

  useEffect(() => {
    setLeadsLoading(true);
    const queryObj = turnSearchStringIntoObject(window.location.search);
    setQueryParamsJson(queryObj);

    if (queryObj.clientId) {
      setSelectedFilterBusiness([+queryObj.clientId]);
    }

    (async () => {
      await dispatch(fetchLeads(+queryObj.clientId || undefined));
    })();

    if (queryParamsJson.clientId) {
      (async () => {
        try {
          const clientInfoResponse = (await axios.get(
            `/clients/${queryParamsJson.clientId}`
          )) as AxiosResponse<apiDBResponse<clientType>>;

          if (
            clientInfoResponse.data.success &&
            clientInfoResponse.data.result
          ) {
            const clientName = clientInfoResponse.data.result[0].companyName;
            setClientName(clientName);
          }
        } catch (err) {
          console.log(err);
          summonFlashMessage('Error Fetching Client Info', 'error');
        }
      })();
    }

    setLeadsLoading(false);
  }, [dispatch, queryParamsJson.clientId]);

  // Filter Settings
  const handleFilterChange = () => setFilterOpen(!filterOpen);

  const handleApplyFilters = () => {
    setAppliedFilters(true);
    setFilterOpen(false);

    const selectedCompanyIds = selectedFilterBusiness;
    const selectedLeadStageIds = selectedLeadStages;
    const selectedOrderBy = orderBy;
    const selectedOrderDirection = orderDirection;

    let filterLeadOutcome = flattenedLeads;

    if (selectedCompanyIds.length) {
      filterLeadOutcome = filterLeadOutcome.filter((lead) =>
        selectedCompanyIds.includes(lead.clientId)
      );
    }

    if (selectedLeadStageIds.length) {
      filterLeadOutcome = filterLeadOutcome.filter((lead) =>
        selectedLeadStageIds.includes(lead.lifeCycle)
      );
    }

    if (selectedOrderBy === 'Created') {
      filterLeadOutcome = filterLeadOutcome.sort((a, b) =>
        a.createdAt < b.createdAt ? -1 : 1
      );
    } else if (selectedOrderBy === 'Updated') {
      filterLeadOutcome = filterLeadOutcome.sort((a, b) =>
        a.updatedAt < b.updatedAt ? -1 : 1
      );
    } else if (selectedOrderBy === 'Alphabetical') {
      filterLeadOutcome = filterLeadOutcome.sort((a, b) =>
        a.firstName < b.firstName ? -1 : 1
      );
    }

    if (selectedOrderDirection === 'DESCENDING') {
      filterLeadOutcome = filterLeadOutcome.reverse();
    }

    setFilteredLeads(filterLeadOutcome);
    summonFlashMessage('Filters Applied', 'info');
  };

  const handleFilterClear = async () => {
    setIsLoadingFilters(true);
    setFilterOpen(false);
    setAppliedFilters(false);
    clearSearchParams();
    setQueryParamsJson({});
    await dispatch(fetchLeads(undefined));
    setSelectedFilterBusiness([]);
    setSelectedLeadStages([]);
    setOrderBy(orderByOptions[0]);
    setOrderDirection(orderDirectionOptions[0]);
    summonFlashMessage('Filters Cleared', 'info');
    setIsLoadingFilters(false);
  };

  const handleExportLeads = async () => {
    try {
      const userBusinessesIds = userBusinesses.map((business) => business.id);

      const orderByConversion = {
        Created: 'createdAt',
        Updated: 'updatedAt',
        Alphabetical: 'alphabetical',
      } as const;

      const orderDirectionConversion = {
        ASCENDING: 'ASC',
        DESCENDING: 'DESC',
      } as const;

      const filtersPayload: filesFilterRequestBody = {
        clientIds: queryParamsJson.clientId
          ? [+queryParamsJson.clientId]
          : userBusinessesIds,
        leadStages: leadStageInformation.filter((stage) =>
          selectedLeadStages.includes(stage.id)
        ),
        sortBy: orderByConversion[orderBy],
        sortDirection: orderDirectionConversion[orderDirection],
      };

      const filesExportResponse = (await axios.post(
        `/files/export/leads`,
        filtersPayload
      )) as AxiosResponse<apiGeneralResponse<{ message: string }>>;

      if (filesExportResponse.data.success) {
        summonFlashMessage(
          'Export Processing, Check Email in a few minutes',
          'success'
        );
      } else {
        summonFlashMessage(
          "Error Sending Export Request, Contact Support at 'contact@leadsnare.io",
          'error'
        );
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Exporting Leads', 'error');
    }
  };

  // Filter Handlers
  // Company Chip Select
  const handleCompanyChange = (
    event: SelectChangeEvent<Array<string | number>>
  ) => {
    const value = event.target.value as Array<string | number>;
    setSelectedFilterBusiness(value);
  };

  // Lead Stage Button Select
  const handleLeadStageChange = (id: number) => {
    if (selectedLeadStages.includes(id)) {
      const filtered = selectedLeadStages.filter((stage) => stage !== id);
      setSelectedLeadStages(filtered);
    } else {
      setSelectedLeadStages([...selectedLeadStages, id]);
    }
  };

  // Order By Button Select
  const handleOrderByChange = (type: (typeof orderByOptions)[number]) =>
    setOrderBy(type);

  // Order Direction Button Select
  const handleOrderDirectionChange = (
    type: (typeof orderDirectionOptions)[number]
  ) => setOrderDirection(type);

  // Accordian Settings
  const NUM_SEP = 10;
  useEffect(() => {
    const val = appliedFilters
      ? filteredLeads.length / NUM_SEP
      : flattenedLeads.length / NUM_SEP;

    const numberOfPages = Math.ceil(val);

    setNumberOfPages(numberOfPages);
  }, [appliedFilters, filteredLeads.length, flattenedLeads.length]);

  const flattenedLeadsChunked = _.chunk(
    appliedFilters ? filteredLeads : flattenedLeads,
    NUM_SEP
  );

  // Leads
  const LeadsComponent: FC = () => {
    const currentLeads = appliedFilters ? filteredLeads : flattenedLeads;

    if (leadSearchValue) {
      return currentLeads
        .filter((lead) =>
          leadSearchValue?.match(`${lead.firstName} ${lead.lastName}`)
        )
        .map((lead) => <Lead lead={lead} />);
    }

    if (!currentLeads || !currentLeads.length) {
      return (
        <Typography sx={{ alignSelf: 'center' }}>
          {appliedFilters
            ? 'No Leads Found Based on your Filters'
            : 'No Leads Found.'}
        </Typography>
      );
    }

    return flattenedLeadsChunked[pageIndex] ? (
      flattenedLeadsChunked[pageIndex]?.map((lead, i) => (
        <Lead key={i} lead={lead} />
      ))
    ) : (
      <Typography sx={{ alignSelf: 'center' }}>End of Leads</Typography>
    );
  };

  // LeadStages
  const orderedStages = [...leadStageInformation].sort((a, b) =>
    a.sortOrder < b.sortOrder ? -1 : 1
  );

  // Autocomplete
  const autoCompleteDisabled = appliedFilters
    ? !filteredLeads
    : !flattenedLeads || !flattenedLeads.length || leadsLoading;

  const autoCompleteOptions = appliedFilters ? filteredLeads : flattenedLeads;

  return (
    <Container ref={ref}>
      <AddLeadModalShell
        contents={
          <AddLeadModal
            closeModal={forceCloseAddLeadModal}
            onSuccess={async () => {
              setLeadsLoading(true);
              await dispatch(fetchLeads());
              setLeadsLoading(false);
            }}
          />
        }
      />
      <HeadContainer>
        <LeadSearchAddContainer>
          <Autocomplete
            disablePortal
            disabled={autoCompleteDisabled}
            options={autoCompleteOptions.map(
              (lead) => `${lead.firstName} ${lead.lastName}`
            )}
            sx={{
              width: '60%',
              maxWidth: 500,
              minWidth: 200,
              marginRight: '20px',
            }}
            renderInput={(params) => (
              <TextField {...params} label="Lead Search" />
            )}
            value={leadSearchValue}
            onChange={(event: any, newValue: string | null) =>
              setLeadSearchValue(newValue)
            }
          />
          <Button
            variant="contained"
            size="large"
            onClick={openAddLeadModal}
            sx={buttonSx}
          >
            {usePlus ? <AddIcon fontSize={'medium'} /> : 'Add Lead'}
          </Button>
        </LeadSearchAddContainer>
        <Accordion
          expanded={filterOpen}
          onChange={handleFilterChange}
          sx={{
            width: '80%',
            minWidth: '200px',
            maxWidth: '700px',
            alignSelf: 'center',
          }}
        >
          <AccordionSummary>
            <Typography sx={{ fontWeight: 'bold' }}>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <HorizontalLine width="100%" color={adminGray} />
            <Typography sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
              Filter By
            </Typography>
            <Typography>
              Filter Leads By Company
              <CompanyChipSelect
                options={userBusinesses.map((business) => ({
                  label: business.companyName,
                  id: business.id,
                }))}
                selectedValues={selectedFilterBusiness}
                handleChange={handleCompanyChange}
                label="Filter By Company"
              />
            </Typography>
            <Typography>
              Filter Leads By Lead Stage
              <Stack
                direction={shouldTransitionFilters ? 'column' : 'row'}
                width={'100%'}
              >
                {orderedStages.map((stage) => {
                  const selected = selectedLeadStages.includes(stage.id);
                  return (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleLeadStageChange(stage.id)}
                      sx={{
                        color: 'black',
                        width: shouldTransitionFilters ? '100%' : '25%',
                        backgroundColor: selected ? orangeMain : 'white',
                        fontWeight: 700,
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'italic',
                        transition: 'all 0.25s ease-in-out',
                        margin: '5px',
                        '&:hover': {
                          transform: 'scale(1.04, 1.02)',
                          boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                          backgroundColor: selected ? orangeMain : 'lightgray',
                        },
                      }}
                    >
                      {stage.type}
                    </Button>
                  );
                })}
              </Stack>
            </Typography>
            <HorizontalLine width="100%" color={adminGray} />
            <Typography sx={{ fontWeight: 'bold', marginBottom: '12px' }}>
              Order By
            </Typography>
            <Typography>
              Sort By Option
              <Stack
                direction={shouldTransitionFilters ? 'column' : 'row'}
                width={'100%'}
              >
                {orderByOptions.map((opt) => {
                  const selected = orderBy === opt;
                  return (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleOrderByChange(opt)}
                      sx={{
                        color: 'black',
                        width: shouldTransitionFilters ? '100%' : '33%',
                        backgroundColor: selected ? orangeMain : 'white',
                        fontWeight: 700,
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'italic',
                        transition: 'all 0.25s ease-in-out',
                        margin: '5px',
                        '&:hover': {
                          transform: 'scale(1.04, 1.02)',
                          boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                          backgroundColor: selected ? orangeMain : 'lightgray',
                        },
                      }}
                    >
                      {opt}
                    </Button>
                  );
                })}
              </Stack>
            </Typography>
            <Typography>
              Sort Direction
              <Stack
                direction={shouldTransitionFilters ? 'column' : 'row'}
                width={'100%'}
              >
                {orderDirectionOptions.map((opt) => {
                  const selected = orderDirection === opt;
                  return (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleOrderDirectionChange(opt)}
                      sx={{
                        color: 'black',
                        width: shouldTransitionFilters ? '100%' : '50%',
                        backgroundColor: selected ? orangeMain : 'white',
                        fontWeight: 700,
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'italic',
                        transition: 'all 0.25s ease-in-out',
                        margin: '5px',
                        '&:hover': {
                          transform: 'scale(1.04, 1.02)',
                          boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
                          backgroundColor: selected ? orangeMain : 'lightgray',
                        },
                      }}
                    >
                      {opt}
                    </Button>
                  );
                })}
              </Stack>
            </Typography>
            <HorizontalLine width="100%" color={adminGray} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Button
                onClick={handleApplyFilters}
                sx={{ ...buttonSx, fontStyle: 'none', marginTop: '10px' }}
              >
                Apply Filters
              </Button>
              <Button
                onClick={handleFilterClear}
                sx={{ ...buttonSx, fontStyle: 'none', marginTop: '10px' }}
              >
                Clear Filters
              </Button>
              <HorizontalLine width="100%" color={adminGray} />
              <Button
                onClick={handleExportLeads}
                sx={{ ...buttonSx, fontStyle: 'none', marginTop: '10px' }}
              >
                Export Leads Based On Filters
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>
      </HeadContainer>
      <Divider sx={{ borderWidth: '1px', borderColor: orangeMain }} />
      <BodyContainer>
        {!leads || !leads.length || leadsLoading || isLoadingFilters ? (
          <Loader isLoading selfAlign />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {queryParamsJson.clientId && (
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {`Leads for ${clientName || queryParamsJson.clientId}`}
              </Typography>
            )}
            <LeadsComponent />
            {NUM_PAGES > 1 && (
              <Pagination
                count={NUM_PAGES}
                onChange={(e, page) => setPageIndex(page - 1)}
                sx={{ alignSelf: 'center', marginTop: '10px' }}
              />
            )}
          </div>
        )}
      </BodyContainer>
    </Container>
  );
};
