import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { IconButton, SxProps, Theme } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';

import { leadStagesSelector } from '../../store/leads';
import {
  userBusinessMapSelector,
  userBusinessesSelector,
} from '../../store/account';

import { LeadDotIndicatorComp } from '../atoms/LeadDotIndicator';
import { InternalTag } from '../atoms/InternalTag';

import { orangeMain } from '../../constants/colors';

import { mobileCheck } from '../../helpers/mobileCheck';

import { leadType } from '../../types';

const Container = styled.div<{ onClick?: any; shouldTransition?: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 0;
  margin: 10px;
  border: 2px solid ${orangeMain};
  border-radius: 5px;
  background-color: white;
  transition: all 0.25s ease-in-out;
  box-shadow: 2px 2px 5px 2px rgba(189, 195, 199, 1);

  ${({ shouldTransition }) =>
    shouldTransition &&
    `
    flex-direction: column;
  `}

  &:hover {
    transform: scale(1.04, 1.02);
    box-shadow: 3px 3px 5px 4px rgba(189, 195, 199, 1);
  }
`;

const LeftRightSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const NameSpan = styled.span`
  font-weight: 600;
  text-align: left;
`;

interface LeadProps {
  lead: leadType;
}

export const Lead: FC<LeadProps> = ({ lead }) => {
  const leadStages = useSelector(leadStagesSelector);
  const userBusinessMap = useSelector(userBusinessMapSelector);
  const userBusiness = useSelector(userBusinessesSelector);

  const hasMoreThanOneBusiness = userBusiness.length > 1;

  const navigate = useNavigate();

  const { firstName, lastName, lifeCycle, id, email, phone } = lead;

  const namedStage = leadStages.find((stage) => stage.id === lifeCycle)?.type;

  const isPhone = mobileCheck();

  const colorSx: SxProps<Theme> = { color: orangeMain };

  const callToActionIcon = isPhone ? (
    <CallIcon sx={colorSx} />
  ) : (
    <MailIcon sx={colorSx} />
  );

  const handleCallToActionClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // Stop the routing from happening
    e.stopPropagation();

    isPhone
      ? window.open(`tel:${phone}`)
      : (window.location.href = `mailto:${email}?subject=LeadSnare`);
  };

  const handleLeadClick = () => {
    navigate(`/lead/${id}`);
  };

  const companyName = userBusinessMap[lead.clientId]?.companyName;

  return (
    <Container onClick={handleLeadClick}>
      <LeftRightSection>
        <div style={{ display: 'flex' }}>
          <NameSpan style={{ margin: '0 10px 10px 0', fontSize: '16px' }}>
            {`${firstName} ${lastName}`}
          </NameSpan>
          <LeadDotIndicatorComp stage={lifeCycle} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <NameSpan style={{ fontSize: '14px', marginRight: '10px' }}>
            {namedStage}
          </NameSpan>
          {companyName && hasMoreThanOneBusiness && (
            <InternalTag style={{ marginTop: '5px' }}>
              {companyName}
            </InternalTag>
          )}
        </div>
      </LeftRightSection>
      <LeftRightSection style={{ alignSelf: 'center' }}>
        <IconButton onClick={(e) => handleCallToActionClick(e)}>
          {callToActionIcon}
        </IconButton>
      </LeftRightSection>
    </Container>
  );
};
