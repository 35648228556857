import React, { FC } from "react";
import styled from "styled-components";
import Paper from "@mui/material/Paper";
import { addressType } from "../../types";

const Container = styled.div``;

const AddressSpan = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
`;

const cardSx = {
  "&.MuiPaper-root": {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem",
  },
};

interface AddressProps {
  address: Partial<addressType>;
}

export const Address: FC<AddressProps> = ({ address }) => {
  return (
    <Paper sx={cardSx}>
      <AddressSpan>{address.address1}</AddressSpan>
      {address.address2 && <AddressSpan>{address.address2}</AddressSpan>}
      <AddressSpan>{`${address.city}, ${address.state}, ${address.zip}`}</AddressSpan>
    </Paper>
  );
};
