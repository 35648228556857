import React, { FC, useLayoutEffect, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { useAuthInfo } from '@propelauth/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';

import { InternalAdminRoute } from './helpers/routeWrapper';
import { PWAPromptMenu } from './helpers/handleMobilePWAInstall';

import { useAppDispatch } from './store';
import {
  fetchUserByPropelAuthId,
  userInfoSelector,
  setPropelAuthId,
  fetchUserClientsByUserId,
  userBusinessesSelector,
} from './store/account';
import {
  ageGenerationsSelector,
  fetchAgeGenerations,
  fetchLeadStages,
  leadStagesSelector,
} from './store/leads';
import {
  pwaBarOpenSelector,
  setIsLeadSnareAdmin,
  setPWASliderBar,
} from './store/site';

import { LandingPage } from './pages/LandingPage';
import { LeadsPage } from './pages/LeadsPage';
import { LeadPage } from './pages/LeadPage';
import { BusinessManagementPage } from './pages/BusinessManagementPage';
import { AccountPage } from './pages/AccountPage';
import { UserManagementPage } from './pages/UserManagementPage';
import { BusinessesPage } from './pages/BusinessesPage';
import { EstimatesPage } from './pages/EstimatesPage';

// Admin Pages
import { AdminPage } from './pages/admin/AdminPage';
import { AdminOnboardingPage } from './pages/admin/AdminOnboardingPage';

import GlobalHeader from './components/organisms/GlobalHeader';

import { PATHS } from './constants/paths';
import SwipeableDrawer from '@mui/material/SwipeableDrawer/SwipeableDrawer';
import { SxProps, Theme } from '@mui/material/styles';
import { getLocalStorageItem } from './helpers/localStorage';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eef2f5;
  // justify-content: space-between; // Not Needed unless Using Footer
  min-width: 100%;
  min-height: 100%;
`;

const drawerStyle: SxProps<Theme> = {
  '& .MuiDrawer-paper': {
    width: '90%',
  },
};

const App: FC = () => {
  const dispatch = useAppDispatch();
  const authInfo = useAuthInfo();

  const userInfo = useSelector(userInfoSelector);
  const leadStageInformation = useSelector(leadStagesSelector);
  const userBusinesses = useSelector(userBusinessesSelector);
  const ageGenerations = useSelector(ageGenerationsSelector);
  const drawerMenuOpen = useSelector(pwaBarOpenSelector);

  const propelAuthId = authInfo?.user?.userId;

  // On Land, Set PropelAuth Id & Admin Status
  useEffect(() => {
    if (authInfo?.isLoggedIn) {
      dispatch(setPropelAuthId(propelAuthId));

      const orgs = authInfo?.orgHelper?.getOrgs();
      if (orgs?.length) {
        const isLeadSnareAdmin = orgs.find((org) => org.orgName === 'Admin');
        if (isLeadSnareAdmin) {
          dispatch(setIsLeadSnareAdmin(true));
        }
      }
    }
  }, [authInfo, dispatch, propelAuthId]);

  // Handle PWA Install Prompt
  useEffect(() => {
    const hasAlreadyPrompted = getLocalStorageItem('hasBeenPWAPrompted');
    const canInstallAsApp = getLocalStorageItem('canInstallAsApp');
    const hasPWABeenInstalled = getLocalStorageItem('hasPWABeenInstalled');
    if (!hasAlreadyPrompted && !hasPWABeenInstalled && canInstallAsApp) {
      dispatch(setPWASliderBar(true));
    }
  }, []);

  // Get Base Site Info
  useLayoutEffect(() => {
    // User Info
    if (propelAuthId && !userInfo) {
      (async () => {
        await dispatch(fetchUserByPropelAuthId(propelAuthId));
      })();
    }

    // Lead Stage Info
    if (!leadStageInformation.length) {
      (async () => {
        await dispatch(fetchLeadStages());
      })();
    }

    // User Businesses
    if (!userBusinesses.length && userInfo?.id) {
      (async () => {
        await dispatch(fetchUserClientsByUserId());
      })();
    }

    // Age Generations
    if (!ageGenerations?.length) {
      (async () => {
        await dispatch(fetchAgeGenerations());
      })();
    }
  }, [
    propelAuthId,
    dispatch,
    userInfo,
    leadStageInformation.length,
    userBusinesses.length,
    ageGenerations.length,
  ]);

  const handleToggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      dispatch(setPWASliderBar(open));
    };

  // const siteInformation = useSelector(siteInformationSelector);

  const isLocal = process.env.NODE_ENV === 'development';

  axios.defaults.baseURL = isLocal
    ? process.env.REACT_APP_LOCAL_SERVER
    : process.env.REACT_APP_PROD_SERVER;

  axios.interceptors.request.use(
    (config) => {
      config.headers['Access-Control-Allow-Origin'] = isLocal
        ? '*'
        : 'https://app.leadsnare.io';
      config.headers['Access-Control-Allow-Methods'] = [
        'GET',
        'POST',
        'PATCH',
        'DELETE',
        'OPTIONS',
      ];
      config.headers['Access-Control-Allow-Headers'] = [
        'Accept',
        'Accept-Encoding',
        'Accept-Language',
        'Access-Control-Allow-Headers',
        // 'Access-Control-Request-Headers',
        'Access-Control-Allow-Credentials',
        // 'Access-Control-Allow-Origin',
        'Access-Control-Allow-Methods',
        'Authorization',
        'Connection',
        'Content-Length',
        'Host',
        'Origin',
        'Referer',
        'Sec-Ch-Ua',
        'Sec-Ch-Ua-Mobile',
        'Sec-Ch-Ua-Platform',
        'Sec-Fetch-Dest',
        'Sec-Fetch-Mode',
        'Sec-Fetch-Site',
        'User-Agent',
        'X-Requested-With',
        'Content-Type',
      ];
      config.headers['Content-Type'] = 'application/json';
      config.headers.Accept = 'application/json';

      const accessToken = authInfo?.accessToken;
      if (accessToken) {
        config.headers['Access-Control-Allow-Credentials'] = true;
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return (
    <Container>
      <GlobalHeader signedIn={false} />
      <SwipeableDrawer
        sx={drawerStyle}
        anchor={'bottom'}
        transitionDuration={{ enter: 300, exit: 250 }}
        open={drawerMenuOpen['bottom']}
        onClose={handleToggleDrawer('bottom', false)}
        onOpen={handleToggleDrawer('bottom', true)}
      >
        <PWAPromptMenu />
      </SwipeableDrawer>
      <Routes>
        {/* Public Routes */}
        <Route path={PATHS.landing} element={<LandingPage />} />
        <Route path={PATHS.leads} element={<LeadsPage />} />
        <Route path={PATHS.lead} element={<LeadPage />} />
        <Route
          path={PATHS.businessInformation}
          element={<BusinessManagementPage />}
        />
        <Route path={PATHS.businesses} element={<BusinessesPage />} />
        <Route path={PATHS.estimates} element={<EstimatesPage />} />
        <Route path={PATHS.account} element={<AccountPage />} />
        <Route path={PATHS.userManagement} element={<UserManagementPage />} />
        {/* Admin Routes */}
        <Route path={PATHS.admin} element={<InternalAdminRoute />}>
          <Route path={PATHS.admin} element={<AdminPage />} />
          <Route
            path={PATHS.adminOnboarding}
            element={<AdminOnboardingPage />}
          />
        </Route>
      </Routes>
      {/* <GlobalFooter /> */}
    </Container>
  );
};

export default App;
