import React, { FC } from 'react';

import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

interface MultipleChipSelectProps {
  options: Array<{ label: string; id: string | number }>;
  selectedValues: Array<string | number>;
  handleChange: (
    selectedOptions: SelectChangeEvent<Array<string | number>>
  ) => void;
  label: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// https://mui.com/material-ui/react-select/#chip
const MultipleChipSelect: FC<MultipleChipSelectProps> = ({
  options,
  selectedValues,
  handleChange,
  label,
}) => {
  return (
    <div>
      <FormControl hiddenLabel sx={{ m: 1, width: '100%' }}>
        <Select
          labelId={`${label}-chip-select`}
          id={`${label}-chip`}
          multiple
          onChange={handleChange}
          value={selectedValues}
          input={<OutlinedInput />}
          renderValue={() => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedValues.map((opt, i) => {
                const name = options.find((o) => o.id === opt)?.label;
                return <Chip key={opt} label={name} />;
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {options.map((opt) => (
            <MenuItem key={opt.id} value={opt.id}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleChipSelect;
