import { RootState } from './index';
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';

export interface SiteState {
  siteInformation: Record<string, string>;
  sideBarOpen: { left: boolean };
  pwaSliderOpen: { bottom: boolean };
  clientIds: Array<string>;
  isLeadSnareAdmin: boolean;
}

const generateInitialState = (): SiteState => ({
  siteInformation: {},
  sideBarOpen: { left: false },
  pwaSliderOpen: { bottom: false },
  clientIds: [],
  isLeadSnareAdmin: false,
});

// ASYNC THUNKS
// export const fetchDefaultDataSet = createAsyncThunk<any>(
//   "questions/fetchDescriptions",
//   async (_, { getState, dispatch }) => {
//     try {
//       const leadsData = await axios.post("/clients/331/leads");
//       return leadsData;
//     } catch (err) {
//       console.error("Error Getting Leads:", err);
//       return [];
//     }
//   }
// );

// REDUCERS
const siteSlice: Slice<SiteState> = createSlice({
  name: 'site',
  initialState: generateInitialState(),
  reducers: {
    setSiteSetting: (
      state: SiteState,
      action: PayloadAction<Record<string, string>>
    ) => {
      state.siteInformation = action.payload;
    },
    setSideBar: (state: SiteState, action: PayloadAction<boolean>) => ({
      ...state,
      sideBarOpen: { left: action.payload },
    }),
    setPWASliderBar: (state: SiteState, action: PayloadAction<boolean>) => ({
      ...state,
      pwaSliderOpen: { bottom: action.payload },
    }),
    setClientIds: (state: SiteState, action: PayloadAction<Array<string>>) => ({
      ...state,
      clientIds: action.payload,
    }),
    setIsLeadSnareAdmin: (
      state: SiteState,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      isLeadSnareAdmin: action.payload,
    }),
  },
  extraReducers: (builder: any) => {},
});

// SELECTORS
export const siteInformationSelector = (state: RootState) =>
  state.site.siteInformation;
export const sideBarOpenSelector = (state: RootState) => state.site.sideBarOpen;
export const pwaBarOpenSelector = (state: RootState) =>
  state.site.pwaSliderOpen;
export const clientIdsSelector = (state: RootState) => state.site.clientIds;
export const leadSnareAdminSelector = (state: RootState) =>
  state.site.isLeadSnareAdmin;

// EXPORTS
export const siteReducer = siteSlice.reducer;
export const {
  setSiteSetting,
  setSideBar,
  setPWASliderBar,
  setIsLeadSnareAdmin,
  setClientIds,
} = siteSlice.actions;
