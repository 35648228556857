import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useResizeDetector } from 'react-resize-detector';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField/TextField';

import { userIdSelector } from '../../../store/account';

import { ModalHeader } from '../../atoms/ModalHeader';
import { adminGray, orangeMain } from '../../../constants/colors';
import HorizontalLine from '../../atoms/HorizontalLine';
import axios, { AxiosResponse } from 'axios';
import { summonFlashMessage } from '../../../helpers/flashMessage';
import { newBusinessRequest } from '../../../types';
import { apiGeneralResponse } from '../../../types/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const inputSx = {
  margin: '5px 0',
};

type FormValues = {
  websiteURL: string;
  companyName: string;
  industry: string;
  businessDescription: string;
  companyPhone: string;
  companyEmail: string;
  businessAddress1: string;
  businessAddress2?: string;
  businessCity: string;
  businessState: string;
  businessZip: string;
};

interface NewBusinessModalProps {
  onSuccess?: () => void;
  closeModal: () => void;
}

export const NewBusinessModal: FC<NewBusinessModalProps> = ({ onSuccess }) => {
  const { ref } = useResizeDetector();
  const userId = useSelector(userIdSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      websiteURL: '',
      companyName: '',
      industry: '',
      businessDescription: '',
      companyPhone: '',
      companyEmail: '',
      businessAddress1: '',
      businessAddress2: '',
      businessCity: '',
      businessState: '',
      businessZip: '',
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const payload: newBusinessRequest = {
        websiteURL: data.websiteURL,
        companyName: data.companyName,
        industry: data.industry,
        businessDescription: data.businessDescription,
        companyPhone: data.companyPhone,
        companyEmail: data.companyEmail,
        businessAddress1: data.businessAddress1,
        businessAddress2: data.businessAddress2,
        businessCity: data.businessCity,
        businessState: data.businessState,
        businessZip: data.businessZip,
      };

      const newBusinessResponse = (await axios.post(
        `clients/new`,
        payload
      )) as AxiosResponse<apiGeneralResponse<{ message: string }>>;

      if (newBusinessResponse.data.success) {
        summonFlashMessage('Business Created Successfully', 'success');
        onSuccess && onSuccess();
      } else {
        summonFlashMessage('Error Creating Business', 'error');
        console.log(newBusinessResponse.data.error);
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Creating Business', 'error');
    }
  };

  return (
    <Container ref={ref}>
      <ModalHeader>New Business</ModalHeader>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          sx={inputSx}
          error={!!errors.companyName}
          label={!!errors.companyName ? 'Error' : 'Business Name'}
          defaultValue="Business Name"
          helperText={!!errors.companyName && 'This Field is Required'}
          {...register('companyName', { required: true })}
        />
        <TextField
          select
          sx={inputSx}
          error={!!errors.industry}
          label={!!errors.industry ? 'Error' : 'Business Industry'}
          defaultValue="1"
          helperText={!!errors.industry && 'This Field is Required'}
          {...register('industry', { required: true })}
        >
          <MenuItem key="1" value="1">
            Test
          </MenuItem>
        </TextField>
        <TextField
          sx={inputSx}
          error={!!errors.companyPhone}
          label={!!errors.companyPhone ? 'Error' : 'Business Phone Number'}
          defaultValue="Business Phone Number"
          helperText={!!errors.companyPhone && 'This Field is Required'}
          {...register('companyPhone', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.companyEmail}
          label={!!errors.companyEmail ? 'Error' : 'Business Email'}
          defaultValue="Business Email"
          helperText={!!errors.companyEmail && 'This Field is Required'}
          {...register('companyEmail', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.websiteURL}
          label={!!errors.websiteURL ? 'Error' : 'Business URL'}
          defaultValue="Business URL"
          helperText={!!errors.websiteURL && 'This Field is Required'}
          {...register('websiteURL', { required: true })}
        />
        <TextField
          multiline
          rows={4}
          sx={inputSx}
          error={!!errors.businessDescription}
          label={
            !!errors.businessDescription ? 'Error' : 'Business Description'
          }
          defaultValue="Business Description"
          helperText={!!errors.businessDescription && 'This Field is Required'}
          {...register('businessDescription', {
            required: true,
          })}
        />
        <HorizontalLine width="100%" color={adminGray} />
        <TextField
          sx={inputSx}
          error={!!errors.businessAddress1}
          label={!!errors.businessAddress1 ? 'Error' : 'Business Address 1'}
          helperText={!!errors.businessAddress1 && 'This Field is Required'}
          {...register('businessAddress1', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.businessAddress2}
          label={!!errors.businessAddress2 ? 'Error' : 'Business Address 2'}
          helperText={!!errors.businessAddress2 && 'This Field is Required'}
          {...register('businessAddress2', { required: false })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.businessCity}
          label={!!errors.businessCity ? 'Error' : 'Business City'}
          helperText={!!errors.businessCity && 'This Field is Required'}
          {...register('businessCity', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.businessState}
          label={!!errors.businessState ? 'Error' : 'Business State'}
          helperText={!!errors.businessState && 'This Field is Required'}
          {...register('businessState', { required: true })}
        />
        <TextField
          sx={inputSx}
          error={!!errors.businessZip}
          label={!!errors.businessZip ? 'Error' : 'Business Zip'}
          helperText={!!errors.businessZip && 'This Field is Required'}
          {...register('businessZip', { required: true })}
        />
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};
