import { useSelector } from 'react-redux';
import { userBusinessesSelector, userInfoSelector } from '../store/account';
import { estimateType } from '../types';

import Paper from '@mui/material/Paper';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Loader } from '../components/atoms/Loader';
import { EstimateFull } from '../components/molecules/EstimateFull';
import { DeleteEstimateModal } from '../components/molecules/modals/DeleteEstimateModal';
import { EditEstimateModalFull } from '../components/molecules/modals/EditEstimateModalFull';
import { summonFlashMessage } from '../helpers/flashMessage';
import { useModal } from '../hooks/UseModal';
import { getLeadByIdFull } from '../types';
import { apiGeneralResponse } from '../types/api';

const cardSx = {
  '&.MuiPaper-root': {
    padding: '30px 15px',
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0',
    width: '90%',
    alignItems: 'center',
    backgroundColor: 'white',
  },
};

export const EstimatesPage = () => {
  const [estimates, setEstimates] = useState<estimateType[]>([]);
  const [estimatesLoading, setEstimatesLoading] = useState<boolean>(false);

  const [estimateToBeEdited, setEstimateToBeEdited] = useState<
    { estimate: estimateType; owner: string } | undefined
  >(undefined);

  const [isEditEstimateModalLoading, setIsEditEstimateModalLoading] =
    useState<boolean>(false);

  const [estimateToBeDeleted, setEstimateToBeDeleted] = useState<
    { estimate: estimateType; owner: string } | undefined
  >(undefined);

  const [isDeleteEstimateModalLoading, setIsDeleteEstimateModalLoading] =
    useState<boolean>(false);

  const [
    openEditEstimateModal,
    EditEstimateModalShell,
    forceCloseEditEstimateModal,
  ] = useModal();

  const [
    openDeleteEstimateModal,
    DeleteEstimateModalShell,
    forceCloseDeleteEstimateModal,
  ] = useModal();

  // get the user information
  const userInformation = useSelector(userInfoSelector);

  // use the userClients to get the estimates for the user
  const userClients = useSelector(userBusinessesSelector);

  const handleEstimateEdit = async (estimate: estimateType) => {
    setIsEditEstimateModalLoading(true);
    openEditEstimateModal();
    const leadInformation = (await fetchLeadInformation(
      estimate.leadId
    )) as getLeadByIdFull;
    const owner = `${leadInformation?.lead?.firstName} ${leadInformation?.lead?.lastName}`;
    const estimateToBeEdited = {
      estimate,
      owner,
    };
    setEstimateToBeEdited(estimateToBeEdited);
    setIsEditEstimateModalLoading(false);
  };

  const handleEstimateDelete = async (estimate: estimateType) => {
    setIsDeleteEstimateModalLoading(true);
    openDeleteEstimateModal();
    const leadInformation = (await fetchLeadInformation(
      estimate.leadId
    )) as getLeadByIdFull;
    const owner = `${leadInformation?.lead?.firstName} ${leadInformation?.lead?.lastName}`;
    const estimateToBeDeleted = {
      estimate,
      owner,
    };

    setEstimateToBeDeleted(estimateToBeDeleted);
    setIsDeleteEstimateModalLoading(false);
  };

  async function fetchEstimates() {
    try {
      if (!!userInformation && userClients.length > 0) {
        // make a request to the server to get the estimates for the client
        const response = await axios.post(
          `/estimates?clientId=${userClients[0].id}`
        );
        setEstimates(response.data.result);
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Fetching Estimates', 'error');
    }
  }

  async function fetchLeadInformation(leadId: number) {
    try {
      const leadResponse = await axios.get<apiGeneralResponse<getLeadByIdFull>>(
        `/leads/${leadId}?withFullInfo=true`
      );

      if (leadResponse.data.success && leadResponse.data) {
        return leadResponse.data.result as getLeadByIdFull;
      }
    } catch (err) {
      console.log(err);
      summonFlashMessage('Error Fetching Lead', 'error');
    }
  }

  useEffect(() => {
    setEstimatesLoading(true);

    fetchEstimates();
    setEstimatesLoading(false);
  }, [userClients, userInformation]);

  return (
    <Paper sx={cardSx}>
      <h1 style={{ marginTop: 0 }}>Estimates</h1>
      {estimatesLoading ? (
        <p>Loading...</p>
      ) : (
        estimates.map((estimate) => {
          return (
            <EstimateFull
              key={estimate.id}
              estimate={estimate}
              onEstimateDelete={() => handleEstimateDelete(estimate)}
              onEstimateEdit={() => handleEstimateEdit(estimate)}
            />
          );
        })
      )}

      <EditEstimateModalShell
        contents={
          isEditEstimateModalLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Loader isLoading={isEditEstimateModalLoading} />
            </div>
          ) : (
            <EditEstimateModalFull
              closeModal={forceCloseEditEstimateModal}
              estimateInformation={estimateToBeEdited?.estimate as estimateType}
              leadName={estimateToBeEdited?.owner as string}
              onSuccess={fetchEstimates}
            />
          )
        }
      />
      <DeleteEstimateModalShell
        contents={
          isDeleteEstimateModalLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Loader isLoading={isDeleteEstimateModalLoading} />
            </div>
          ) : (
            <DeleteEstimateModal
              estimate={estimateToBeDeleted?.estimate as estimateType}
              target={estimateToBeDeleted?.owner as string}
              closeModal={forceCloseDeleteEstimateModal}
              onSuccess={fetchEstimates}
            />
          )
        }
      />
    </Paper>
  );
};
