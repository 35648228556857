import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useAuthInfo, useLogoutFunction } from '@propelauth/react';

import { IconButton, SxProps, Theme } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';

import { useAppDispatch } from '../../store';
import { setSideBar, sideBarOpenSelector } from '../../store/site';

import { LeftDrawerMenu } from './LeftDrawerMenu';

import { Button } from '../atoms/Button';
import { LeadSnareLogo } from '../atoms/icons/LeadSnareLogo';

import { PATHS } from '../../constants/paths';
import { orangeMain } from '../../constants/colors';
import { mobileCheck } from '../../helpers/mobileCheck';
import { useResizeDetector } from 'react-resize-detector';
const Header = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Modified shadow */
`;

const Container = styled.div`
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 20px;
`;

const AuthButton = styled(Button)`
  padding: 10px 20px;
  color: black;
  font-weight: 600;
  background-color: ${orangeMain};

  &:hover {
    transform: scale(1.04, 1.02);
    box-shadow: 0px 5px 10px rgba(189, 195, 199, 1);
  }
`;

const LogoContainer = styled.div`
  display: flex;
`;

interface GlobalHeaderProps {
  signedIn: boolean;
}

const GlobalHeader: FC<GlobalHeaderProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authInfo = useAuthInfo();
  const logoutFn = useLogoutFunction();
  const drawerMenuOpen = useSelector(sideBarOpenSelector);
  const { width, ref } = useResizeDetector();

  const shouldMinimizeLogo = width && width <= 420; // #blaze it
  const isMobile = mobileCheck();

  const shouldShrinkLogo = shouldMinimizeLogo || isMobile;

  const handleLogout = () => {
    window.localStorage.clear();
    logoutFn(true);
  };

  const onLogoClick = () => {
    if (window.location.pathname === PATHS.landing) {
      return;
    }

    navigate(PATHS.landing);
  };

  const handleToggleDrawer =
    (anchor: string, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      dispatch(setSideBar(open));
    };

  const drawerStyle: SxProps<Theme> = {
    '& .MuiDrawer-paper': {
      maxWidth: '25rem',
      width: '80%',
    },
  };

  return (
    <Header>
      <SwipeableDrawer
        sx={drawerStyle}
        anchor={'left'}
        transitionDuration={{ enter: 300, exit: 250 }}
        open={drawerMenuOpen['left']}
        onClose={handleToggleDrawer('left', false)}
        onOpen={handleToggleDrawer('left', true)}
      >
        <LeftDrawerMenu />
      </SwipeableDrawer>
      <Container ref={ref}>
        <IconButton
          onClick={handleToggleDrawer('left', !drawerMenuOpen['left'])}
        >
          <MenuIcon fontSize="large" sx={{ color: 'black' }} />
        </IconButton>
        <LeadSnareLogo
          onClick={onLogoClick}
          height={shouldShrinkLogo ? 40 : 55}
          width={shouldShrinkLogo ? 90 : 130}
        />
        {!authInfo.loading && authInfo.isLoggedIn && (
          <LogoContainer>
            <AuthButton onClick={handleLogout}>Logout</AuthButton>
          </LogoContainer>
        )}
      </Container>
    </Header>
  );
};

export default GlobalHeader;
