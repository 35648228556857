import React, { FC } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Stack from '@mui/material/Stack';

import { leadStageType } from '../../types';

const solidColors: Record<leadStageType['id'], string> = {
  // Lead
  1: '#FFEA4D',

  // Prospect
  2: '#FF4D62',

  // Customer
  3: '#62FF4D',

  // Partner
  4: '#cccccc',
};

const LeadDotIndicator = ({ id }: { id: number }) => (
  <FiberManualRecordIcon sx={{ color: solidColors[id] }} fontSize={'small'} />
);

interface LeadDotIndicatorProps {
  stage: leadStageType['id'];
}

const Prospect = () => (
  <Stack direction={'row'}>
    <LeadDotIndicator id={2} />
  </Stack>
);
const Lead = () => (
  <Stack direction={'row'}>
    <LeadDotIndicator id={1} />
  </Stack>
);
const Customer = () => (
  <Stack direction={'row'}>
    <LeadDotIndicator id={3} />
  </Stack>
);
const Partner = () => (
  <Stack direction={'row'}>
    <LeadDotIndicator id={4} />
  </Stack>
);

export const LeadDotIndicatorComp: FC<LeadDotIndicatorProps> = ({ stage }) => {
  switch (stage) {
    case 1:
      return <Lead />;
    case 2:
      return <Prospect />;
    case 3:
      return <Customer />;
    case 4:
      return <Partner />;
    default:
      return <Prospect />;
  }
};
