import { leadStageType } from '../types';

export const orangeMain = '#FF914D';
export const deleteRed = '#FC7B7B';
export const adminGray = '#E5E5E5';

/**
 * Hardcode Colors for now but eventually we will
 * add color background to the DB for
 * each leadStage
 */

/**
 * 1 = Lead
 * 2 = Prospect
 * 3 = Customer
 * 4 = Partner
 */
export const leadStageColors: Record<leadStageType['id'], string> = {
  // Lead
  1: 'linear-gradient(150deg, #FFEA4D 0%, #ffd1b4 65%, #ffffff 100%)',

  // Prospect
  2: 'linear-gradient(150deg, #FF4D62 0%, #ffe6d8 65%, #ffffff 100%)',

  // Customer
  3: 'linear-gradient(150deg, #62FF4D 0%, #ffb98d 65%, #ffffff 100%)',

  // Partner
  // 4: 'linear-gradient(150deg, #ffffff 0%, #fce4d8 40%, #faddcd 100%)',
  // 4: 'linear-gradient(150deg, #cccccc 0%, #ececec 65%, #ffffff 100%)',
  4: 'linear-gradient(150deg, #ffffff 0%, #ececec 65%, #cccccc 100%)',
};

export const leadStageColorsSolid: Record<leadStageType['id'], string> = {
  // Lead
  1: '#FFEA4D',

  // Prospect
  2: '#FF4D62',

  // Customer
  3: '#62FF4D',

  // Partner
  4: '#cccccc',
};
