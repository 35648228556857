import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import { SxProps, Theme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel';
import Chip from '@mui/material/Chip/Chip';
import Stack from '@mui/material/Stack/Stack';
import Switch from '@mui/material/Switch/Switch';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';

import { useAppDispatch } from '../../../store';
import {
  addLead,
  ageGenerationsSelector,
  leadStagesSelector,
} from '../../../store/leads';
import { userBusinessesSelector } from '../../../store/account';

import HorizontalLine from '../../atoms/HorizontalLine';
import { ModalHeader, ModalSubHeader } from '../../atoms/ModalHeader';

import {
  adminGray,
  leadStageColorsSolid,
  orangeMain,
} from '../../../constants/colors';
import { leadStageType } from '../../../types';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import FormControl from '@mui/material/FormControl/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import InputLabel from '@mui/material/InputLabel/InputLabel';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  align-items: center;
`;

const inputSx = {
  width: '100%',
  '& .MuiOutlinedInput-input': {
    padding: '10px',
  },
};

const labelSx = {
  '&.MuiInputLabel-root': {
    lineHeight: 1,
  },
};

const tabsSx: SxProps<Theme> = {
  '.MuiTabs-indicator': { backgroundColor: orangeMain },
  width: '100%',

  '.MuiTab-root': {
    width: '25%',
    maxWidth: 'none',
  },
};

const withFullWidth = {
  width: '100%',
  maxWidth: 'none',

  '.MuiTab-root': {
    width: '100%',
    maxWidth: 'none',
  },
};

const tabSx = (id: number) => ({
  border: 'none',
  padding: '0px 10px',

  minWidth: '25%',

  '&.MuiTab-textColorPrimary': {
    color: 'black',
  },

  '&.Mui-selected': {
    backgroundColor: leadStageColorsSolid[id],
  },
});

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: 'Male' | 'Female' | 'Non-Binary';
  age: string;
  companyAddress1?: string;
  companyAddress2?: string;
  companyCity?: string;
  companyState?: string;
  companyZip?: string;
  serviceAddress1?: string;
  serviceAddress2?: string;
  serviceCity?: string;
  serviceState?: string;
  serviceZip?: string;
  selectedCompany: { label: string; value: number };
  tags: Array<string>;
};

interface AddLeadModalProps {
  closeModal: () => void;
  onSuccess?: () => Promise<void>;
}

export const AddLeadModal: FC<AddLeadModalProps> = ({
  closeModal,
  onSuccess,
}) => {
  const { width, ref } = useResizeDetector();
  const dispatch = useAppDispatch();
  const userBusinesses = useSelector(userBusinessesSelector);
  const leadStageInformation = useSelector(leadStagesSelector);
  const ageGenerations = useSelector(ageGenerationsSelector);

  const sortedLeadStages = [...leadStageInformation].sort((a, b) =>
    a.sortOrder > b.sortOrder ? 1 : -1
  );

  const userClients = userBusinesses.map((business) => ({
    label: business.companyName,
    value: business.id,
  }));

  const [addBusinessAddress, setAddBusinessAddress] = useState(false);
  const [addServiceAddress, setAddServiceAddress] = useState(false);
  const [currentTagInputValue, setCurrentTagInputValue] = useState('');

  const shouldTransition = width && width >= 450;

  const [tags, setTags] = useState<Array<string>>([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyAddress1: '',
      companyAddress2: '',
      companyCity: '',
      companyState: '',
      companyZip: '',
      serviceAddress1: '',
      serviceAddress2: '',
      serviceCity: '',
      serviceState: '',
      serviceZip: '',
      selectedCompany: {},
    },
  });

  const [lifeCycleValue, setLifeCycleValue] = useState<number>(0);

  const handleLifeCycleChange = (_: React.SyntheticEvent, newValue: number) =>
    setLifeCycleValue(newValue);

  const handleLifeCycleChangeMobile = (event: SelectChangeEvent<number>) => {
    const value = event.target.value as number;
    setLifeCycleValue(value);
  };

  const handleTagAddition = () => {
    if (currentTagInputValue) {
      setTags([...tags, currentTagInputValue]);
    }

    setCurrentTagInputValue('');

    // Hardcoded bad practice but it works so fuck it
    (document.getElementById('tagInput') as any).value = '';
  };

  const handleTagDelete = (val: string) => {
    const newTags = tags.filter((tag) => tag !== val);
    setTags(newTags);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    console.log(data);
    const businessAddress = {
      address1: data.companyAddress1,
      address2: data.companyAddress2,
      city: data.companyCity,
      state: data.companyState,
      zip: data.companyZip,
    };

    const serviceAddress = {
      address1: data.serviceAddress1,
      address2: data.serviceAddress2,
      city: data.serviceCity,
      state: data.serviceState,
      zip: data.serviceZip,
    };

    const newLead = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      gender: data.gender,
      age: +data.age,
      tags,
      address: {},
      serviceLocation: {},
      lifeCycle: sortedLeadStages[lifeCycleValue].id,
      // If the user only has 1 company, just default that company
      clientId:
        userClients.length === 1
          ? userClients[0].value
          : data.selectedCompany.value,
    };

    if (addBusinessAddress) {
      newLead.address = businessAddress;
    }

    if (addServiceAddress) {
      newLead.serviceLocation = serviceAddress;
    }

    await dispatch(addLead(newLead));

    if (onSuccess) {
      closeModal();
      await onSuccess();
    } else {
      closeModal();
    }
  };

  return (
    <Container ref={ref}>
      <ModalHeader>New Lead</ModalHeader>
      <HorizontalLine width="100%" color={adminGray} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
          Lead Information
        </ModalSubHeader>
        <TextField
          margin="dense"
          sx={inputSx}
          InputLabelProps={{ sx: labelSx }}
          error={!!errors.firstName}
          label="First Name*"
          helperText={!!errors.firstName && 'This Field is Required'}
          {...register('firstName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.lastName}
          label='Last Name or "N/A*'
          helperText={!!errors.lastName && 'This Field is Required'}
          {...register('lastName', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.email}
          label="Email*"
          helperText={!!errors.email && 'This Field is Required'}
          {...register('email', { required: true })}
        />
        <TextField
          margin="dense"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          error={!!errors.phone}
          label={'Phone Number*'}
          helperText={!!errors.phone && 'This Field is Required'}
          {...register('phone', { required: true })}
        />
        <FormControl sx={{ m: 1, width: '100%' }}>
          <InputLabel id="age-select-label">Age</InputLabel>
          <Select
            labelId="age-generation-select-label"
            id="age-generation-select"
            value={getValues('age')}
            label="Age"
            onChange={(e) => {
              setValue('age', e.target.value);
            }}
          >
            {ageGenerations.map((gen) => {
              const range = `${gen.yearStart} - ${gen.yearEnd}`;
              const name = gen.generation;
              return <MenuItem value={gen.id}>{`${name} (${range})`}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: '100%' }}>
          <InputLabel id="gender-select-label">Gender</InputLabel>
          <Select
            labelId="gender-select-label"
            id="gender-select"
            value={getValues('gender')}
            label="Gender"
            onChange={(e) => {
              if (['Male', 'Female', 'Non-Binary'].includes(e.target.value)) {
                setValue(
                  'gender',
                  e.target.value as 'Male' | 'Female' | 'Non-Binary'
                );
              }
            }}
          >
            {['Male', 'Female', 'Non-Binary'].map((gender) => {
              return <MenuItem value={gender}>{gender}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <HorizontalLine width="100%" color={adminGray} />
        {userClients.length > 1 && (
          <>
            <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
              Company
            </ModalSubHeader>
            <Autocomplete
              disablePortal
              options={userClients}
              onChange={(_, newValue) => {
                if (newValue) {
                  setValue('selectedCompany', newValue);
                }
              }}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} label="Company" />
              )}
            />
            <HorizontalLine width="100%" color={adminGray} />
          </>
        )}
        <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
          Stage
        </ModalSubHeader>
        {!!shouldTransition ? (
          <Tabs
            sx={{ ...withFullWidth, ...tabsSx }}
            onChange={handleLifeCycleChange}
            orientation="horizontal"
            centered
            value={lifeCycleValue}
          >
            {sortedLeadStages &&
              sortedLeadStages.map((stage) => (
                <Tab sx={tabSx(stage.id)} key={stage.id} label={stage.type} />
              ))}
          </Tabs>
        ) : (
          <FormControl sx={{ m: 1, width: '100%' }}>
            <InputLabel id="lifecycle-select-label">Stage</InputLabel>

            <Select
              labelId="lifecycle-select-label"
              id="lifecycle-select"
              value={lifeCycleValue}
              onChange={handleLifeCycleChangeMobile}
              label="Stage"
            >
              {sortedLeadStages.map((stage) => (
                <MenuItem key={stage.id} value={stage.sortOrder - 1}>
                  {stage.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <HorizontalLine width="100%" color={adminGray} />
        <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
          Service Address
        </ModalSubHeader>
        <FormControlLabel
          style={{ alignSelf: 'flex-start' }}
          control={
            <Switch
              checked={addServiceAddress}
              onChange={() => setAddServiceAddress(!addServiceAddress)}
            />
          }
          label="Add Service Address?"
        />
        {addServiceAddress && (
          <>
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.serviceAddress1}
              label="Service Address 1"
              helperText={!!errors.serviceAddress1 && 'This Field is Required'}
              {...register('serviceAddress1', { required: false })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.serviceAddress2}
              label="Service Address 2"
              helperText={!!errors.serviceAddress2 && 'This Field is Required'}
              {...register('serviceAddress2', { required: false })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.serviceCity}
              label="Service City"
              helperText={!!errors.serviceCity && 'This Field is Required'}
              {...register('serviceCity', { required: false })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.serviceState}
              label="Service State"
              helperText={!!errors.serviceState && 'This Field is Required'}
              {...register('serviceState', { required: false })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.serviceZip}
              label="Service Zip Code"
              helperText={!!errors.serviceZip && 'This Field is Required'}
              {...register('serviceZip', { required: false })}
            />
          </>
        )}
        <HorizontalLine width="100%" color={adminGray} />
        <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
          Company Address
        </ModalSubHeader>
        <FormControlLabel
          style={{ alignSelf: 'flex-start' }}
          control={
            <Switch
              checked={addBusinessAddress}
              onChange={() => setAddBusinessAddress(!addBusinessAddress)}
            />
          }
          label="Add Business Address?"
        />
        {addBusinessAddress && (
          <>
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.companyAddress1}
              label="Company Address 1*"
              helperText={!!errors.companyAddress1 && 'This Field is Required'}
              {...register('companyAddress1', { required: addBusinessAddress })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.companyAddress2}
              label="Company Address 2"
              helperText={!!errors.companyAddress2 && 'This Field is Required'}
              {...register('companyAddress2', { required: false })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.companyCity}
              label="Company City*"
              helperText={!!errors.companyCity && 'This Field is Required'}
              {...register('companyCity', { required: addBusinessAddress })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.companyState}
              label="Company State*"
              helperText={!!errors.companyState && 'This Field is Required'}
              {...register('companyState', { required: addBusinessAddress })}
            />
            <TextField
              margin="dense"
              InputLabelProps={{ sx: labelSx }}
              sx={inputSx}
              error={!!errors.companyZip}
              label="Company Zip Code*"
              helperText={!!errors.companyZip && 'This Field is Required'}
              {...register('companyZip', { required: addBusinessAddress })}
            />
          </>
        )}
        <HorizontalLine width="100%" color={adminGray} />
        <ModalSubHeader style={{ alignSelf: 'flex-start' }}>
          Tags
        </ModalSubHeader>
        <Stack direction="row" spacing={1} style={{ alignSelf: 'flex-start' }}>
          {tags.map((tag) => (
            <Chip
              label={tag}
              variant="outlined"
              onDelete={() => handleTagDelete(tag)}
            />
          ))}
        </Stack>
        <TextField
          margin="dense"
          id="tagInput"
          InputLabelProps={{ sx: labelSx }}
          sx={inputSx}
          onChange={(e) => setCurrentTagInputValue(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          onClick={handleTagAddition}
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            width: '100%',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Add Tag
        </Button>
        <HorizontalLine width="100%" color={adminGray} />
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{
            color: 'black',
            backgroundColor: orangeMain,
            fontWeight: 700,
            fontFamily: 'Montserrat, sans-serif',
            fontStyle: 'italic',
            margin: '10px 0 0 0',
            width: '100%',
            transition: 'all 0.25s ease-in-out',

            '&:hover': {
              transform: 'scale(1.04, 1.02)',
              boxShadow: '5px 5px 10px rgba(189, 195, 199, 1)',
              backgroundColor: orangeMain,
            },
          }}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};
